@use '../mixin/mixin' as *;
@use '../setting/setting' as *;

.c-mv {
  .inner-block {
    padding: min(63px, #{vw(63,1366)}) 15px min(10px, #{vw(10,1366)});
    @include sp {
      padding: 56px 20px 30px;
    }
  }
  &.under {
    .inner-block {
      padding: min(49px, #{vw(49,1366)}) min(15px, #{vw(20,1366)}) min(45px, #{vw(45,1366)});
      @include sp {
        padding: 32px 20px 25px;
      }
    }
  }
}