/* -----------------------------------------------
* home Module
* homeページ用
-------------------------------------------------- */
@use '../../mixin/mixin' as *;

.functions {
  .c-lead-block {
    @include sp {
      margin-bottom: 35px;
    }
  }
  .functions-block {
    .inner-block {
      padding-top: min(84px, #{vw(84,1366)});
      padding-bottom: min(120px, #{vw(120,1366)});
      @include sp {
        padding-top: vw(40,375);
        padding-bottom: vw(60,375);
      }
    }
  }
}
