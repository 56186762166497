@charset "UTF-8";
:root {
  --font-family-Lato-B: "Lato-Bold", sans-serif;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* -----------------------------------------------
* Plugins エントリーポイント
-------------------------------------------------- */
/*------------------------------------------------------------------------------
  reset
------------------------------------------------------------------------------*/
html,
input,
textarea,
select,
button {
  font-family: "NotoSans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", Meiryo, sans-serif;
  font-weight: 400;
}

html {
  color: #333;
  background: #fff;
  font-size: min(16px, 1.1713030747vw);
  line-height: 1.6;
  letter-spacing: 0.06em;
  overflow-wrap: break-word;
}
@media only screen and (max-width: 640px) {
  html {
    font-size: 14px;
  }
}

body {
  background: #fff;
  margin: 0;
}
@media only screen and (max-width: 640px) {
  body {
    position: relative;
    -webkit-appearance: none;
    -webkit-text-size-adjust: 100%;
  }
  body:not(.home) {
    padding-bottom: 54px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

img {
  border: 0;
  margin: 0;
  vertical-align: top;
  max-width: 100%;
  height: auto;
}

figure {
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}
@media only screen and (min-width: 641px) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}

input,
select,
textarea,
button {
  color: inherit;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  appearance: none;
}

@media only screen and (max-width: 640px) {
  input,
select,
textarea {
    font-size: 16px !important;
  }
}

select::-ms-expand {
  display: none;
}

textarea {
  resize: vertical;
}

::-webkit-input-placeholder {
  color: #ccc;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-ms-input-placeholder {
  color: #ccc;
}

::placeholder {
  color: #ccc;
}

button {
  border: none;
  cursor: pointer;
}

label {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

main {
  display: block;
}
@media only screen and (min-width: 641px) {
  main {
    padding-top: min(130px, 9.5168374817vw);
  }
}
@media only screen and (min-width: 641px) and (max-width: 870px) {
  main {
    padding-top: 6.8965517241vw;
  }
}
@media only screen and (max-width: 640px) {
  main {
    padding-top: 60px;
  }
}

#wrapper {
  position: relative;
}

.inner-block {
  margin: 0 auto;
  position: relative;
}
@media only screen and (min-width: 641px) {
  .inner-block {
    padding: 0 15px;
    max-width: 1260px;
    margin: auto;
    width: 100%;
  }
}
@media only screen and (max-width: 640px) {
  .inner-block {
    padding: 0 20px;
  }
}

dl, dt, dd {
  padding: 0;
  margin: 0;
}

@media only screen and (min-width: 641px) {
  a,
a::before,
a::after,
button,
button::before,
button::after {
    transition: 0.3s ease-in-out;
  }
}

.ib {
  display: inline-block;
}

@media only screen and (max-width: 640px) {
  .pc {
    display: none !important;
  }
}

@media only screen and (min-width: 641px) {
  .sp {
    display: none !important;
  }
}

@font-face {
  src: url(../font/NotoSansJP-Regular.otf) format("opentype"), url(../font/NotoSansJP-Regular.woff) format("woff");
  font-family: "NotoSans";
  font-weight: 400;
  font-display: swap;
}
@font-face {
  src: url(../font/NotoSansJP-Bold.otf) format("opentype"), url(../font/NotoSansJP-Bold.woff) format("woff");
  font-family: "NotoSans";
  font-weight: 700;
  font-display: swap;
}
@font-face {
  src: url(../font/Lato-Bold.ttf) format("truetype");
  font-family: "Lato-Bold";
  font-weight: 700;
  font-display: swap;
}
.c-svg {
  display: inline-block;
  fill: currentColor;
  vertical-align: top;
}

.c-en {
  font-family: var(--font-family-Lato-B);
}

.bg-blue {
  background: #ECF4F8;
}

.bg-skyblue {
  background: #13ACC4;
}

.bg-gray {
  background: #F8F8F8;
}

.c-anchor {
  position: relative;
  top: -80px;
}

.c-blue {
  color: #13ACC4;
}

.adv-area {
  position: relative;
  overflow: hidden;
}

/* --------------------------------
c-header
----------------------------------- */
.c-header {
  position: absolute;
  transition: 0.3s ease-in-out;
  width: 100%;
  z-index: 999;
}
@media only screen and (min-width: 641px) and (min-width: 871px) {
  .c-header.is-fixed {
    position: fixed;
    top: 0;
    transform: translateY(0) !important;
  }
  .c-header.is-fixed .sub {
    display: none;
  }
  .c-header.is-fixed .header-top {
    padding: min(22px, 1.6105417277vw) min(15px, 1.0980966325vw) min(7px, 0.5124450952vw);
  }
}
@media only screen and (min-width: 641px) and (min-width: 871px) and (max-width: 1135px) {
  .c-header.is-fixed .header-top {
    padding-top: min(25px, 1.8301610542vw);
  }
}
@media only screen and (min-width: 641px) and (min-width: 871px) {
  .c-header.is-fixed .header-bottom {
    display: none;
  }
}
@media only screen and (min-width: 641px) and (min-width: 871px) {
  .c-header.is-fixed .c-menu {
    margin-left: min(50px, 3.6603221083vw);
  }
}
@media only screen and (min-width: 641px) and (min-width: 871px) and (max-width: 1135px) {
  .c-header.is-fixed .c-menu {
    margin-left: min(20px, 1.7621145374vw);
  }
}
@media only screen and (min-width: 641px) and (min-width: 871px) {
  .c-header.is-fixed .c-menu > li > a {
    color: #0A616F;
    font-size: min(13px, 0.9516837482vw);
    padding: 0;
  }
  .c-header.is-fixed .c-menu > li > a:hover {
    color: #13ACC4;
  }
  .c-header.is-fixed .c-menu > li + li {
    margin-left: min(25px, 1.8301610542vw);
  }
}
@media only screen and (min-width: 641px) and (min-width: 871px) and (max-width: 1135px) {
  .c-header.is-fixed .c-menu > li + li {
    margin-left: min(15px, 2.2026431718vw);
  }
}
@media only screen and (min-width: 641px) and (min-width: 871px) {
  .c-header.is-fixed .c-menu > li .balloon {
    top: min(2px, 0.1464128843vw);
    bottom: 0;
    left: max(-95px, -6.9546120059vw);
    margin: auto;
    padding: min(3px, 0.2196193265vw) min(7px, 0.5124450952vw);
  }
}
@media only screen and (min-width: 641px) and (min-width: 871px) and (max-width: 1135px) {
  .c-header.is-fixed .c-menu > li .balloon {
    top: max(-20px, -1.4641288433vw);
    left: max(-70px, -5.1244509517vw);
    bottom: inherit;
  }
}
@media only screen and (min-width: 641px) and (min-width: 871px) {
  .c-header.is-fixed .c-menu > li .balloon::after {
    border-width: min(5px, 0.3660322108vw) 0 min(5px, 0.3660322108vw) min(11px, 0.8052708638vw);
    height: min(11px, 0.8052708638vw);
    width: min(11px, 0.8052708638vw);
    position: absolute;
    bottom: 0;
    top: 0;
    margin: auto;
    transform: rotate(0);
  }
}
@media only screen and (min-width: 641px) and (min-width: 871px) and (max-width: 1135px) {
  .c-header.is-fixed .c-menu > li .balloon::after {
    border-width: min(3px, 0.2643171806vw) 0 min(3px, 0.2643171806vw) min(6px, 0.7929515419vw);
    height: min(6px, 0.5286343612vw);
    width: min(6px, 0.5286343612vw);
    right: max(-2px, -0.1762114537vw);
    bottom: max(-10px, -0.8810572687vw);
    transform: rotate(35deg);
  }
}
@media only screen and (min-width: 641px) and (min-width: 871px) {
  .c-header.is-fixed .c-menu > li:not(:first-child)::before, .c-header.is-fixed .c-menu > li:first-child::before {
    content: none;
  }
}
@media only screen and (min-width: 641px) and (min-width: 871px) {
  .c-header.is-fixed .header-btn_wrap .balloon {
    display: block;
  }
}
@media only screen and (max-width: 870px) {
  .c-header {
    position: fixed;
  }
}
.c-header .header-top {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: min(22px, 1.6105417277vw) min(42px, 3.074670571vw) min(7px, 0.5124450952vw) min(40px, 2.9282576867vw);
}
@media only screen and (max-width: 870px) {
  .c-header .header-top {
    border-bottom: 1px solid #E3E3E3;
    padding: 2.0689655172vw 2.2988505747vw;
    position: fixed;
    top: 0;
    width: 100%;
  }
}
@media only screen and (max-width: 870px) and (max-width: 640px) {
  .c-header .header-top {
    padding: 19px 20px 17px;
  }
}
@media only screen and (max-width: 870px) {
  .c-header .header-top.is-open {
    background: #0A616F;
  }
  .c-header .header-top.is-open a .is-close {
    display: none;
  }
  .c-header .header-top.is-open a .is-open {
    display: block;
  }
}
.c-header .header-bottom {
  background: #0A616F;
  padding: min(17px, 1.2445095168vw) 0 min(18px, 1.317715959vw);
  position: relative;
}
@media only screen and (max-width: 870px) {
  .c-header .header-bottom {
    position: fixed;
    top: 6.7816091954vw;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 20px 20px 20px;
    z-index: 990;
    display: none;
  }
}
@media only screen and (max-width: 870px) and (max-width: 640px) {
  .c-header .header-bottom {
    top: 60px;
  }
}
@media only screen and (max-width: 870px) {
  .c-header .header-bottom.is-open {
    display: block;
  }
}
.c-header .logo {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 870px) {
  .c-header .logo {
    position: relative;
    z-index: 999;
  }
}
.c-header .logo a {
  max-width: min(164px, 12.0058565154vw);
}
@media only screen and (max-width: 870px) {
  .c-header .logo a {
    max-width: 14.5977011494vw;
  }
}
@media only screen and (max-width: 870px) and (max-width: 640px) {
  .c-header .logo a {
    max-width: 127px;
  }
}
.c-header .logo a .is-open {
  display: none;
}
.c-header .logo .sub {
  font-size: min(11px, 0.8052708638vw);
  font-weight: 400;
  margin-left: min(20px, 1.4641288433vw);
}
@media only screen and (max-width: 870px) {
  .c-header .logo .sub {
    display: none;
  }
}
@media only screen and (min-width: 641px) and (min-width: 871px) {
  .c-header .header-btn_wrap {
    display: flex;
    flex-wrap: wrap;
  }
  .c-header .header-btn_wrap .balloon {
    background: #FFB100;
    border-radius: 100px;
    color: #fff;
    font-size: min(10px, 0.7320644217vw);
    font-weight: bold;
    padding: min(3px, 0.2196193265vw) min(5px, 0.3660322108vw) min(4px, 0.2928257687vw) min(6px, 0.439238653vw);
    position: absolute;
    top: max(-18px, -1.317715959vw);
    left: 0;
    right: 0;
    margin: auto;
    max-width: 61px;
  }
}
@media only screen and (min-width: 641px) and (min-width: 871px) and (max-width: 1135px) {
  .c-header .header-btn_wrap .balloon {
    padding: 0 min(4px, 0.3524229075vw) 0 min(5px, 0.4405286344vw);
  }
}
@media only screen and (min-width: 641px) and (min-width: 871px) {
  .c-header .header-btn_wrap .balloon::before {
    border-style: solid;
    border-width: min(6px, 0.439238653vw) min(5px, 0.3660322108vw) 0 min(5px, 0.3660322108vw);
    border-color: #FFB100 transparent transparent transparent;
    content: "";
    display: inline-block;
    height: min(6px, 0.439238653vw);
    width: min(10px, 0.7320644217vw);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: max(-4px, -0.2928257687vw);
    right: max(-7px, -0.3660322108vw);
  }
}
@media only screen and (max-width: 870px) {
  .c-header .header-btn_wrap {
    display: none !important;
    margin-top: 30px;
  }
  .c-header .header-btn_wrap .balloon {
    display: none;
  }
}
@media only screen and (min-width: 641px) and (min-width: 871px) {
  .c-header .header-btn_wrap li {
    width: calc((100% - min(6px, 0.439238653vw)) / 3);
  }
  .c-header .header-btn_wrap li:not(:first-child) {
    margin-left: min(3px, 0.2196193265vw);
  }
}
@media only screen and (max-width: 870px) {
  .c-header .header-btn_wrap li + li {
    margin-top: 15px;
  }
}
.c-header .header-btn_wrap a {
  border-radius: 100px;
  display: block;
  font-size: min(14px, 1.0248901903vw);
  font-weight: bold;
  padding: min(8px, 0.5856515373vw) min(10px, 0.7320644217vw);
  text-align: center;
  position: relative;
  width: 100%;
}
@media only screen and (min-width: 641px) and (min-width: 871px) {
  .c-header .header-btn_wrap a {
    max-width: min(150px, 10.980966325vw);
    min-width: min(150px, 10.980966325vw);
  }
}
@media only screen and (min-width: 641px) {
  .c-header .header-btn_wrap a:hover.docs {
    background: #fff;
    color: #0A616F;
  }
  .c-header .header-btn_wrap a:hover.docs .inn::before {
    background: url(/img/common/icon_docs_gr.svg) no-repeat center center;
    background-size: contain;
    transition: 0.3s ease-in-out;
  }
  .c-header .header-btn_wrap a:hover.contact {
    background: #13ACC4;
    color: #fff;
  }
  .c-header .header-btn_wrap a:hover.contact .inn::before {
    background: url(/img/common/icon_mail_wh.svg) no-repeat center center;
    background-size: contain;
    transition: 0.3s ease-in-out;
  }
  .c-header .header-btn_wrap a:hover.online {
    background: #fff;
  }
}
@media only screen and (max-width: 870px) {
  .c-header .header-btn_wrap a {
    font-size: 14px;
    padding: 8px 10px;
  }
}
.c-header .header-btn_wrap a .inn {
  position: relative;
}
.c-header .header-btn_wrap a .inn::before {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  bottom: 0;
  left: 0;
  margin: auto;
}
.c-header .header-btn_wrap a.docs {
  background: #0A616F;
  border: 1px solid #0A616F;
  color: #fff;
}
.c-header .header-btn_wrap a.docs .inn {
  padding-left: min(16px, 1.1713030747vw);
}
@media only screen and (max-width: 870px) {
  .c-header .header-btn_wrap a.docs .inn {
    padding-left: 16px;
  }
}
.c-header .header-btn_wrap a.docs .inn::before {
  background: url(/img/common/icon_docs.svg) no-repeat center center;
  background-size: contain;
  height: min(14px, 1.0248901903vw);
  width: min(11px, 0.8052708638vw);
  transition: 0.3s ease-in-out;
}
@media only screen and (max-width: 870px) {
  .c-header .header-btn_wrap a.docs .inn::before {
    height: 17px;
    width: 14px;
  }
}
.c-header .header-btn_wrap a.contact {
  background: #fff;
  border: 1px solid #13ACC4;
  color: #13ACC4;
}
.c-header .header-btn_wrap a.contact .inn {
  padding-left: min(23px, 1.6837481698vw);
}
@media only screen and (max-width: 870px) {
  .c-header .header-btn_wrap a.contact .inn {
    padding-left: 23px;
  }
}
.c-header .header-btn_wrap a.contact .inn::before {
  background: url(/img/common/icon_mail.svg) no-repeat center center;
  background-size: contain;
  height: min(13px, 0.9516837482vw);
  width: min(18px, 1.317715959vw);
  transition: 0.3s ease-in-out;
}
@media only screen and (max-width: 870px) {
  .c-header .header-btn_wrap a.contact .inn::before {
    height: 13px;
    width: 18px;
  }
}
.c-header .header-btn_wrap a.online {
  background: #FFDB14;
  border: 1px solid #FFDB14;
}
@media only screen and (min-width: 641px) {
  .c-header .header-btn_wrap a.online {
    min-width: min(160px, 11.7130307467vw);
  }
}
.c-header .header-btn_wrap a.online .inn {
  padding-left: min(16px, 1.1713030747vw);
}
@media only screen and (max-width: 870px) {
  .c-header .header-btn_wrap a.online .inn {
    padding-left: 16px;
  }
}
.c-header .header-btn_wrap a.online .inn::before {
  background: url(/img/common/icon_cursor.svg) no-repeat center center;
  background-size: contain;
  height: min(12px, 0.878477306vw);
  width: min(12px, 0.878477306vw);
}
@media only screen and (max-width: 870px) {
  .c-header .header-btn_wrap a.online .inn::before {
    height: 12px;
    width: 12px;
  }
}
.c-header .header-btn_wrap a.online .balloon {
  background: #FF6C00;
  border-color: #FF6C00;
  color: #fff;
}
@media only screen and (max-width: 870px) {
  .c-header .header-btn_wrap a.online .balloon {
    left: -20px;
  }
}
.c-header .header-btn_wrap a.online .balloon::before {
  border-color: #FF6C00 transparent transparent transparent;
}
@media only screen and (min-width: 641px) and (min-width: 871px) {
  .c-header .c-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.c-header .c-menu > li {
  position: relative;
}
@media only screen and (min-width: 641px) {
  .c-header .c-menu > li:not(.hv-li)::before {
    background: #fff;
    border-radius: 100px;
    content: "";
    display: block;
    height: 12px;
    width: 1px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
}
@media only screen and (min-width: 641px) and (max-width: 870px) {
  .c-header .c-menu > li:not(.hv-li)::before {
    content: none;
  }
}
@media only screen and (min-width: 641px) and (min-width: 871px) {
  .c-header .c-menu > li:first-child::before {
    right: 0;
    left: inherit;
  }
}
@media only screen and (max-width: 870px) {
  .c-header .c-menu > li + li {
    border-top: 1px solid #ddd;
  }
}
.c-header .c-menu > li > a {
  color: #fff;
  font-size: min(15px, 1.0980966325vw);
  font-weight: bold;
  padding: 0 min(40px, 2.9282576867vw);
}
@media only screen and (min-width: 641px) {
  .c-header .c-menu > li > a:hover {
    color: #FFDB14;
  }
}
@media only screen and (max-width: 870px) {
  .c-header .c-menu > li > a {
    display: block;
    font-size: 20px;
    padding: 29px 0 29px 23px;
    position: relative;
  }
  .c-header .c-menu > li > a::before {
    content: "";
    width: 6px;
    height: 6px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
}
.c-header .c-menu > li .balloon {
  background: #FF6C00;
  border-radius: min(20px, 1.4641288433vw);
  color: #fff;
  display: inline-block;
  font-size: min(10px, 0.7320644217vw);
  font-weight: bold;
  padding: min(4px, 0.2928257687vw) min(11px, 0.8052708638vw);
  letter-spacing: 0;
  position: absolute;
  top: max(-10px, -0.7320644217vw);
  left: max(-58px, -4.2459736457vw);
}
@media only screen and (max-width: 1060px) {
  .c-header .c-menu > li .balloon {
    left: max(-65px, -4.7584187408vw);
  }
}
@media only screen and (max-width: 870px) {
  .c-header .c-menu > li .balloon {
    display: none;
  }
}
.c-header .c-menu > li .balloon::after {
  border-style: solid;
  border-width: min(5px, 0.3660322108vw) 0 min(5px, 0.3660322108vw) min(11px, 0.8052708638vw);
  border-color: transparent transparent transparent #FF6C00;
  content: "";
  display: inline-block;
  height: min(11px, 0.5124450952vw);
  width: min(11px, 0.5124450952vw);
  position: absolute;
  bottom: 0;
  right: max(-4px, -0.2928257687vw);
}
@media only screen and (min-width: 641px) {
  .c-header .c-menu > li .balloon::after {
    transform: rotate(36deg);
  }
}
@media only screen and (max-width: 870px) {
  .c-header .c-menu > li .balloon::after {
    height: 5px;
    width: 5px;
    bottom: -5px;
    right: 0;
    left: 0;
    margin: auto;
    transform: rotate(90deg);
  }
}
.c-header .c-menu > li.hv-li {
  position: static;
}
@media only screen and (min-width: 641px) {
  .c-header .c-menu > li.hv-li:hover .hover-menu {
    opacity: 1;
    visibility: visible;
    transition: 0.3s ease-in-out;
  }
}
.c-header .hover-menu {
  background: #F4F4F4;
  position: absolute;
  top: min(60px, 4.39238653vw);
  left: 0;
  width: 100%;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out;
}
.c-header .hover-menu .inner-block {
  padding: min(59px, 4.3191800878vw) min(16px, 1.1713030747vw) min(59px, 4.3191800878vw);
  display: flex;
}
.c-header .hover-menu .menu-title {
  color: #0A616F;
  font-size: min(20px, 1.4641288433vw);
  font-weight: bold;
  letter-spacing: 0;
  margin-right: min(128px, 9.3704245974vw);
  white-space: nowrap;
}
.c-header .hover-menu .sub-menu {
  display: flex;
  flex-wrap: wrap;
}
.c-header .hover-menu .sub-menu li {
  width: calc((100% - min(100px, 7.3206442167vw)) / 3);
}
@media only screen and (min-width: 641px) {
  .c-header .hover-menu .sub-menu li:nth-child(3n+1) {
    width: min(350px, 25.6222547584vw);
  }
  .c-header .hover-menu .sub-menu li:nth-child(3n+2) {
    max-width: min(270px, 19.7657393851vw);
  }
}
.c-header .hover-menu .sub-menu li:nth-child(n+4) {
  margin-top: min(30px, 2.196193265vw);
}
.c-header .hover-menu .sub-menu li a {
  color: #333;
  font-size: min(16px, 1.1713030747vw);
  font-weight: 400;
  position: relative;
  padding-left: min(14px, 1.0248901903vw);
}
.c-header .hover-menu .sub-menu li a::before {
  content: "";
  width: min(6px, 0.439238653vw);
  height: min(6px, 0.439238653vw);
  border-top: 1px solid #13ACC4;
  border-right: 1px solid #13ACC4;
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
@media only screen and (min-width: 641px) {
  .c-header .hover-menu .sub-menu li a:hover {
    color: #13ACC4;
  }
}

/* --------------------------------
バーガーメニュー
----------------------------------- */
@media only screen and (min-width: 641px) and (min-width: 871px) {
  .btn-menu {
    display: none;
  }
}
@media only screen and (max-width: 870px) {
  .btn-menu {
    background: #0A616F;
    width: 7.4712643678vw;
    height: 6.8965517241vw;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
  }
}
@media only screen and (max-width: 870px) and (max-width: 640px) {
  .btn-menu {
    width: 65px;
    height: 60px;
  }
}
@media only screen and (max-width: 870px) {
  .btn-menu .inn {
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 2.2988505747vw;
    height: 2.183908046vw;
  }
}
@media only screen and (max-width: 870px) and (max-width: 640px) {
  .btn-menu .inn {
    width: 20px;
    height: 19px;
  }
}
@media only screen and (max-width: 870px) {
  .btn-menu .line:nth-of-type(2)::after {
    top: 0;
    content: "";
  }
}
@media only screen and (max-width: 870px) {
  .btn-menu .line,
.btn-menu .line:nth-of-type(2)::after {
    background: #fff;
    display: inline-block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    transition: 0.3s ease-in-out;
    transition-property: transform;
  }
}
@media only screen and (max-width: 870px) {
  .btn-menu .line:nth-of-type(1) {
    top: 0;
    transform-origin: right center;
  }
}
@media only screen and (max-width: 870px) {
  .btn-menu .line:nth-of-type(3) {
    top: 1.8390804598vw;
    transform-origin: left center;
  }
}
@media only screen and (max-width: 870px) and (max-width: 640px) {
  .btn-menu .line:nth-of-type(3) {
    top: 16px;
  }
}
@media only screen and (max-width: 870px) {
  .btn-menu .line:nth-of-type(2) {
    top: 0.9195402299vw;
  }
}
@media only screen and (max-width: 870px) and (max-width: 640px) {
  .btn-menu .line:nth-of-type(2) {
    top: 8px;
  }
}
@media only screen and (max-width: 870px) {
  .btn-menu .line:nth-of-type(1),
.btn-menu .line:nth-of-type(3) {
    transition: 0.2s;
    transition-property: transform;
    transition-delay: 0.3s;
  }
}
@media only screen and (max-width: 870px) {
  .btn-menu.is-open .line:nth-of-type(1), .btn-menu.is-open .line:nth-of-type(3) {
    transform: scaleX(0) translate3d(0, 0, 0);
    transition-delay: 0s;
  }
}
@media only screen and (max-width: 870px) {
  .btn-menu.is-open .line:nth-of-type(2), .btn-menu.is-open .line:nth-of-type(2)::after {
    transition-delay: 0.3s;
  }
}
@media only screen and (max-width: 870px) {
  .btn-menu.is-open .line:nth-of-type(2) {
    transform: rotate(45deg) translate3d(0, 0, 0);
  }
}
@media only screen and (max-width: 870px) {
  .btn-menu.is-open .line:nth-of-type(2)::after {
    transform: rotate(90deg) translate3d(0, 0, 0);
  }
}

.c-footer .inner-block {
  max-width: 100%;
}
.c-footer .footer-top {
  background: #fff;
  border-top: 1px solid #ddd;
  position: relative;
}
@media only screen and (max-width: 640px) {
  .c-footer .footer-top {
    background: #ECF4F8;
  }
}
.c-footer .footer-top .inner-block {
  padding: min(30px, 2.196193265vw) min(130px, 2.9282576867vw);
}
@media only screen and (max-width: 640px) {
  .c-footer .footer-top .inner-block {
    padding: 20px 20px 14px;
  }
}
.c-footer .footer-top .c-menu {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (min-width: 641px) {
  .c-footer .footer-top .c-menu li + li {
    margin-left: min(40px, 2.9282576867vw);
  }
}
@media only screen and (max-width: 640px) {
  .c-footer .footer-top .c-menu li {
    margin-right: 30px;
    margin-bottom: 3px;
  }
}
.c-footer .footer-top .c-menu li a {
  font-size: min(15px, 1.0980966325vw);
  font-weight: 400;
}
@media only screen and (min-width: 641px) {
  .c-footer .footer-top .c-menu li a:hover {
    color: #13ACC4;
  }
}
@media only screen and (max-width: 640px) {
  .c-footer .footer-top .c-menu li a {
    font-size: min(13px, 3.4666666667vw);
    letter-spacing: 0;
    line-height: 1.6923;
  }
}
.c-footer .footer-bottom {
  background: #97999B;
}
.c-footer .footer-bottom .inner-block {
  padding: min(50px, 3.6603221083vw) min(130px, 2.9282576867vw) min(20px, 1.4641288433vw);
}
@media only screen and (max-width: 640px) {
  .c-footer .footer-bottom .inner-block {
    padding: 20px;
  }
}
.c-footer .footer-bottom .logo-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 640px) {
  .c-footer .footer-bottom .logo-wrap .logo {
    max-width: 102px;
  }
  .c-footer .footer-bottom .logo-wrap .catchcopy {
    max-width: 141px;
  }
}
.c-footer .copyright {
  color: #fff;
  font-size: min(13px, 0.9516837482vw);
  font-weight: 400;
  letter-spacing: 0;
  text-align: center;
  margin-top: min(10px, 7.3206442167vw);
}
@media only screen and (max-width: 640px) {
  .c-footer .copyright {
    font-size: 10px;
    margin-top: 30px;
  }
}

#pagetop {
  position: fixed;
  right: 0;
  bottom: 0;
}
#pagetop a {
  background: #ECF7F8;
  display: block;
  height: min(85px, 6.2225475842vw);
  width: min(85px, 6.2225475842vw);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: #0A616F;
  font-size: min(12px, 0.878477306vw);
  font-weight: 400;
  padding-top: min(9px, 0.6588579795vw);
  position: relative;
  text-align: center;
}
@media only screen and (min-width: 641px) and (max-width: 920px) {
  #pagetop a {
    height: min(70px, 9.2391304348vw);
    width: min(70px, 9.2391304348vw);
  }
}
@media only screen and (min-width: 641px) {
  #pagetop a:hover {
    background: #13ACC4;
    color: #fff;
  }
  #pagetop a:hover::before {
    border-color: #fff;
  }
}
@media only screen and (max-width: 640px) {
  #pagetop a {
    height: 60px;
    width: 60px;
    font-size: 10px;
  }
}
#pagetop a::before {
  content: "";
  display: inline-block;
  width: min(8px, 0.5856515373vw);
  height: min(8px, 0.5856515373vw);
  border-top: 1px solid #0A616F;
  border-left: 1px solid #0A616F;
  transform: rotate(45deg);
  position: absolute;
  top: max(-15px, -1.0980966325vw);
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
@media only screen and (max-width: 640px) {
  #pagetop a::before {
    left: 3px;
    height: 6px;
    width: 6px;
  }
}

.c-title01 .c-en {
  color: #13ACC4;
  display: block;
  font-size: min(18px, 1.317715959vw);
  font-weight: bold;
  line-height: 1.333;
}
@media only screen and (max-width: 640px) {
  .c-title01 .c-en {
    font-size: 14px;
    line-height: 2;
  }
}
.c-title01 .main {
  display: block;
  font-size: min(40px, 2.9282576867vw);
  font-weight: bold;
  margin-top: min(5px, 0.3660322108vw);
  line-height: 1.7;
  letter-spacing: 0;
}
@media only screen and (max-width: 640px) {
  .c-title01 .main {
    font-size: 24px;
    letter-spacing: 0;
  }
}
@media only screen and (max-width: 640px) {
  .c-title01 .main.big {
    font-size: 26px;
  }
}
.c-title01.small .c-en {
  font-size: min(16px, 1.1713030747vw);
}
@media only screen and (max-width: 640px) {
  .c-title01.small .c-en {
    font-size: 13px;
    letter-spacing: 0;
    line-height: 1;
  }
}
.c-title01.small .main {
  font-size: min(32px, 2.3426061493vw);
  margin-top: 0;
  letter-spacing: 0.06em;
}
@media only screen and (max-width: 640px) {
  .c-title01.small .main {
    font-size: 26px;
  }
}
.c-title01.center {
  text-align: center;
}

.c-title02 {
  font-weight: bold;
  font-size: min(32px, 2.3426061493vw);
  position: relative;
  letter-spacing: 0.07em;
  padding-top: min(42px, 3.074670571vw);
  margin-bottom: min(32px, 2.3426061493vw);
  line-height: 1.6875;
}
@media only screen and (max-width: 640px) {
  .c-title02 {
    font-size: 22px;
    padding-top: 32px;
    margin-bottom: 18px;
    line-height: 1.6818;
    letter-spacing: 0;
  }
}
.c-title02::before {
  background: url(/img/common/icon_line.svg) no-repeat center center;
  background-size: contain;
  content: "";
  display: block;
  height: min(5px, 0.3660322108vw);
  width: min(48px, 3.513909224vw);
  position: absolute;
  top: 0;
  left: 0;
}
@media only screen and (max-width: 640px) {
  .c-title02::before {
    width: 49px;
    height: 5px;
  }
}
.c-title02.small {
  font-size: min(24px, 1.756954612vw);
  letter-spacing: 0;
  padding-top: min(20px, 1.4641288433vw);
  margin-bottom: min(12px, 0.878477306vw);
}
@media only screen and (max-width: 640px) {
  .c-title02.small {
    font-size: 18px;
    padding-top: 20px;
    margin-bottom: 27px;
  }
}
.c-title02.small::before {
  height: min(3px, 0.2196193265vw);
  width: min(34px, 2.4890190337vw);
}
@media only screen and (max-width: 640px) {
  .c-title02.small::before {
    height: 3px;
    width: 34px;
  }
}

.c-title03 {
  font-size: min(22px, 1.6105417277vw);
  font-weight: bold;
  padding-left: min(15px, 1.0980966325vw);
  position: relative;
  line-height: 1.6818;
  margin-top: min(55px, 4.0263543192vw);
  margin-bottom: min(20px, 1.4641288433vw);
}
@media only screen and (max-width: 640px) {
  .c-title03 {
    font-size: 16px;
    padding-left: 15px;
    margin-top: 18px;
    margin-bottom: 10px;
  }
}
.c-title03:not(.sub)::before {
  background: #13ACC4;
  border-radius: 2px;
  height: min(10px, 0.7320644217vw);
  width: min(10px, 0.7320644217vw);
  top: min(14px, 1.0248901903vw);
}
@media only screen and (max-width: 640px) {
  .c-title03:not(.sub)::before {
    height: 10px;
    width: 10px;
    top: 8px;
  }
}
.c-title03::before {
  content: "";
  position: absolute;
  left: 0;
}
.c-title03.sub {
  margin-top: min(35px, 2.5622254758vw);
  padding-left: min(20px, 1.4641288433vw);
}
@media only screen and (max-width: 640px) {
  .c-title03.sub {
    padding-left: 15px;
    margin-top: 25px;
  }
}
.c-title03.sub::before {
  content: "※";
}

.c-title04 {
  font-size: min(24px, 1.756954612vw);
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.7;
  margin-bottom: min(32px, 2.3426061493vw);
}
@media only screen and (max-width: 640px) {
  .c-title04 {
    font-size: 18px;
    margin-bottom: 22px;
  }
}

@media only screen and (max-width: 640px) {
  .c-title05 {
    display: flex;
    flex-wrap: wrap;
  }
  .c-title05.center {
    align-items: center;
  }
}
.c-title05 .num {
  color: #13ACC4;
  display: inline-block;
  font-size: min(80px, 5.8565153734vw);
  position: relative;
  text-align: center;
  padding-top: min(14px, 1.0248901903vw);
  line-height: 1;
  margin-bottom: min(25px, 1.8301610542vw);
  letter-spacing: 0.08em;
}
@media only screen and (max-width: 640px) {
  .c-title05 .num {
    font-size: min(52px, 13.8666666667vw);
    width: min(64px, 17.0666666667vw);
    margin-bottom: 0;
    padding-top: min(10px, 2.6666666667vw);
    white-space: nowrap;
  }
}
.c-title05 .num::before {
  background: #13ACC4;
  border-radius: 100px;
  content: "";
  display: block;
  height: 4px;
  width: 100%;
  position: absolute;
  left: min(3px, 0.2196193265vw);
  top: max(-1px, -0.0732064422vw);
  max-width: min(92px, 6.7349926794vw);
}
@media only screen and (max-width: 640px) {
  .c-title05 .num::before {
    height: 3px;
    max-width: 58px;
  }
}
.c-title05 .num .en {
  display: block;
  font-size: min(26px, 1.9033674963vw);
  letter-spacing: 0.1em;
  line-height: 1;
  position: relative;
  left: max(-2px, -0.1464128843vw);
}
@media only screen and (max-width: 640px) {
  .c-title05 .num .en {
    font-size: min(16px, 4.2666666667vw);
    left: max(-1px, -0.2666666667vw);
  }
}
.c-title05 .main {
  display: block;
  font-size: min(28px, 2.0497803807vw);
  font-weight: bold;
  line-height: 1.7;
  letter-spacing: 0.06em;
}
@media only screen and (max-width: 640px) {
  .c-title05 .main {
    font-size: 20px;
    margin-left: min(20px, 5.3333333333vw);
    width: calc(100% - min(84px, 22.4vw));
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 641px) {
  .c-title05.l-flex {
    align-items: center;
  }
  .c-title05.l-flex .num {
    width: min(106px, 7.7598828697vw);
  }
  .c-title05.l-flex .main {
    margin-top: min(15px, 1.0980966325vw);
    margin-left: min(23px, 1.6837481698vw);
    letter-spacing: 0.057em;
    width: calc(100% - min(129px, 9.4436310395vw));
  }
  .c-title05.l-flex + .text {
    margin-top: min(20px, 1.4641288433vw) !important;
  }
}
@media only screen and (max-width: 640px) {
  .c-title05.l-flex {
    display: flex !important;
    flex-wrap: wrap;
  }
}

.bg-skyblue .c-title01 .c-en {
  color: #fff;
}
.bg-skyblue .c-title01 .main {
  color: #fff;
}

.c-mv .inner-block {
  padding: min(63px, 4.6120058565vw) 15px min(10px, 0.7320644217vw);
}
@media only screen and (max-width: 640px) {
  .c-mv .inner-block {
    padding: 56px 20px 30px;
  }
}
.c-mv.under .inner-block {
  padding: min(49px, 3.5871156662vw) min(15px, 1.4641288433vw) min(45px, 3.2942898975vw);
}
@media only screen and (max-width: 640px) {
  .c-mv.under .inner-block {
    padding: 32px 20px 25px;
  }
}

.c-breadcrumb {
  padding: min(40px, 2.9282576867vw) 15px min(55px, 4.0263543192vw);
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 1260px;
}
@media only screen and (max-width: 640px) {
  .c-breadcrumb {
    flex-wrap: nowrap;
    padding: 24px 20px 35px;
    overflow: scroll;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .c-breadcrumb::-webkit-scrollbar {
    display: none;
  }
}
.c-breadcrumb li {
  position: relative;
}
.c-breadcrumb li + li {
  margin-left: min(24px, 1.756954612vw);
}
@media only screen and (min-width: 641px) and (max-width: 1000px) {
  .c-breadcrumb li + li {
    margin-left: 2.4vw;
  }
}
@media only screen and (max-width: 640px) {
  .c-breadcrumb li + li {
    margin-left: 14px;
  }
}
.c-breadcrumb li + li::before {
  background: #13ACC4;
  content: "";
  display: block;
  height: min(14px, 1.0248901903vw);
  width: 1px;
  transform: rotate(15deg);
  position: absolute;
  top: 4px;
  left: max(-11px, -0.8052708638vw);
  bottom: 0;
  margin: auto;
}
@media only screen and (min-width: 641px) and (max-width: 1000px) {
  .c-breadcrumb li + li::before {
    top: 2px;
    left: -1.2vw;
  }
}
@media only screen and (max-width: 640px) {
  .c-breadcrumb li + li::before {
    height: 10px;
    left: -7px;
    top: 3px;
  }
}
.c-breadcrumb li .inn {
  font-size: min(13px, 0.9516837482vw);
  letter-spacing: 0;
}
@media only screen and (max-width: 640px) {
  .c-breadcrumb li .inn {
    font-size: 10px;
  }
}
@media only screen and (min-width: 641px) {
  .c-breadcrumb li a:hover {
    color: #13ACC4;
  }
}
.c-breadcrumb.under {
  padding: min(36px, 2.635431918vw) 18px min(58px, 4.2459736457vw);
}
@media only screen and (max-width: 640px) {
  .c-breadcrumb.under {
    padding: 20px 20px 37px;
  }
}

.anchor-block .inner-block {
  padding-bottom: min(80px, 5.8565153734vw);
}
@media only screen and (max-width: 640px) {
  .anchor-block .inner-block {
    padding-bottom: 40px;
  }
}

.range-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: min(20px, 1.4641288433vw);
}
@media only screen and (max-width: 730px) {
  .range-wrap {
    display: none;
  }
}
.range-wrap .range {
  border-left: 5px dotted #F4F4F4;
  color: #676767;
  font-size: min(14px, 1.0248901903vw);
  font-weight: bold;
  text-align: center;
  position: relative;
  padding: min(3px, 0.2196193265vw) min(10px, 0.7320644217vw) min(4px, 0.2928257687vw);
}
.range-wrap .range .inn {
  display: block;
  position: relative;
}
.range-wrap .range .inn::before, .range-wrap .range .inn::after {
  background: #ddd;
  content: "";
  display: block;
  height: 1px;
  width: min(133px, 9.7364568082vw);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.range-wrap .range .inn::before {
  left: min(10px, 0.7320644217vw);
}
.range-wrap .range .inn::after {
  right: min(10px, 0.7320644217vw);
}
.range-wrap .range::before, .range-wrap .range::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  border-style: solid;
  height: min(9px, 0.6588579795vw);
  width: min(12px, 0.878477306vw);
}
.range-wrap .range::before {
  border-width: min(6px, 0.439238653vw) min(9px, 0.6588579795vw) min(6px, 0.439238653vw) 0;
  border-color: transparent #ddd transparent transparent;
  left: min(10px, 0.7320644217vw);
}
.range-wrap .range::after {
  border-width: min(6px, 0.439238653vw) 0 min(6px, 0.439238653vw) min(9px, 0.6588579795vw);
  border-color: transparent transparent transparent #ddd;
  right: min(10px, 0.7320644217vw);
}
.range-wrap .range:first-child {
  width: 34%;
}
.range-wrap .range:last-child {
  border-right: 5px dotted #F4F4F4;
  width: 66%;
}
.range-wrap .range:last-child .inn::before, .range-wrap .range:last-child .inn::after {
  width: min(340px, 24.8901903367vw);
}

.c-anchor-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 1230px;
  margin: auto;
}
@media only screen and (min-width: 641px) {
  .c-anchor-list {
    box-shadow: 0px 3px 14px rgba(181, 181, 181, 0.16);
  }
}
.c-anchor-list li {
  width: calc(100% / 6);
}
@media only screen and (min-width: 641px) {
  .c-anchor-list li:first-child .white-box {
    border-left: 1px solid #ddd;
  }
  .c-anchor-list li:last-child .white-box {
    border-right: 1px solid #ddd;
  }
  .c-anchor-list li:nth-child(4) .arrow-title .inn::after {
    border-color: transparent transparent transparent #13ACC4;
  }
  .c-anchor-list li:nth-child(5) .arrow-title .inn::after {
    border-color: transparent transparent transparent #13ACC4;
  }
}
@media only screen and (min-width: 641px) and (min-width: 731px) {
  .c-anchor-list li + li .white-box::before {
    background: #ddd;
    content: "";
    display: block;
    height: calc(100% - min(20px, 1.4641288433vw));
    width: 1px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  .c-anchor-list li + li .arrow-title .inn {
    display: block;
    position: relative;
  }
  .c-anchor-list li + li .arrow-title .inn::before, .c-anchor-list li + li .arrow-title .inn::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    border-style: solid;
    border-width: min(30px, 2.196193265vw) 0 min(30px, 2.196193265vw) min(14px, 1.0248901903vw);
    z-index: 10;
  }
}
@media only screen and (min-width: 641px) and (min-width: 731px) and (max-width: 1050px) {
  .c-anchor-list li + li .arrow-title .inn::before, .c-anchor-list li + li .arrow-title .inn::after {
    border-width: min(35px, 3.3333333333vw) 0 min(35px, 3.3333333333vw) min(19px, 1.8095238095vw);
  }
}
@media only screen and (min-width: 641px) and (min-width: 731px) {
  .c-anchor-list li + li .arrow-title .inn:before {
    left: max(-8px, -0.5856515373vw);
    border-color: transparent transparent transparent #fff;
  }
}
@media only screen and (min-width: 641px) and (min-width: 731px) {
  .c-anchor-list li + li .arrow-title .inn:after {
    left: max(-10px, -0.7320644217vw);
    border-color: transparent transparent transparent #1380C4;
  }
}
@media only screen and (max-width: 730px) {
  .c-anchor-list li {
    box-shadow: 0px 3px 14px rgba(181, 181, 181, 0.4);
    border-radius: 5px;
    width: calc((100% - 19px) / 2);
  }
  .c-anchor-list li:nth-child(2n+2) {
    margin-left: 19px;
  }
  .c-anchor-list li:nth-child(n+3) {
    margin-top: 20px;
  }
}
.c-anchor-list a {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.c-anchor-list a::before {
  content: "";
  width: 6px;
  height: 6px;
  border-bottom: 2px solid #13ACC4;
  border-right: 2px solid #13ACC4;
  transform: rotate(45deg);
  position: absolute;
  bottom: min(18px, 1.317715959vw);
  left: 0;
  right: 0;
  margin: auto;
}
@media only screen and (max-width: 640px) {
  .c-anchor-list a::before {
    width: 5px;
    height: 5px;
    bottom: 10px;
  }
}
@media only screen and (min-width: 641px) {
  .c-anchor-list a:hover .title {
    color: #13ACC4;
    transition: 0.3s ease-in-out;
  }
}
.c-anchor-list .arrow-title {
  background: #13ACC4;
  color: #fff;
  display: block;
  font-size: min(14px, 1.0248901903vw);
  font-weight: bold;
  text-align: center;
  padding: min(13px, 0.9516837482vw) min(10px, 0.7320644217vw) min(12px, 0.878477306vw);
  overflow: hidden;
}
@media only screen and (max-width: 640px) {
  .c-anchor-list .arrow-title {
    border-radius: 5px 5px 0 0;
    font-size: 13px;
    padding: 3px 10px 2px;
  }
}
.c-anchor-list .arrow-title.blue {
  background: #1380C4;
}
.c-anchor-list .arrow-title.skyblue {
  background: #13ACC4;
}
.c-anchor-list .arrow-title.green {
  background: #48B5AA;
}
.c-anchor-list .arrow-title.green .inn::after {
  border-color: transparent transparent transparent #48B5AA;
}
.c-anchor-list .white-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: min(20px, 1.4641288433vw) min(10px, 0.7320644217vw) min(42px, 3.074670571vw);
  position: relative;
  flex-grow: 1;
}
@media only screen and (min-width: 641px) {
  .c-anchor-list .white-box {
    border-bottom: 1px solid #ddd;
  }
}
@media only screen and (max-width: 640px) {
  .c-anchor-list .white-box {
    border-radius: 0 0 5px 5px;
    padding: 10px 10px 26px;
  }
}
.c-anchor-list .white-box .title {
  display: block;
  font-size: min(16px, 1.1713030747vw);
  font-weight: bold;
  line-height: 1.4375;
  margin-top: min(18px, 1.317715959vw);
  text-align: center;
  transition: 0.3s ease-in-out;
}
@media only screen and (max-width: 640px) {
  .c-anchor-list .white-box .title {
    font-size: min(13px, 3.4666666667vw);
    line-height: 1.3;
    margin-top: 15px;
    letter-spacing: 0;
  }
}
@media only screen and (max-width: 640px) {
  .c-anchor-list .white-box .icon {
    display: flex;
    align-items: center;
    max-width: 60px;
    min-height: 60px;
  }
}

.c-list01 li {
  padding-left: min(30px, 2.196193265vw);
  position: relative;
  line-height: 1.5;
}
@media only screen and (max-width: 640px) {
  .c-list01 li {
    font-size: 14px;
    padding-left: 19px;
  }
}
.c-list01 li::before {
  background: url(/img/common/check.svg) no-repeat center center;
  background-size: contain;
  content: "";
  height: min(20px, 1.4641288433vw);
  width: min(21px, 1.5373352855vw);
  position: absolute;
  top: min(2px, 0.1464128843vw);
  left: 0;
}
@media only screen and (max-width: 640px) {
  .c-list01 li::before {
    height: 12px;
    width: 13px;
    top: 6px;
  }
}
.c-list01 li + li {
  margin-top: min(10px, 0.7320644217vw);
}
@media only screen and (max-width: 640px) {
  .c-list01 li + li {
    margin-top: 10px;
  }
}
.c-list01.bg-gray {
  padding: min(22px, 1.6105417277vw) min(22px, 1.6105417277vw);
}
@media only screen and (max-width: 640px) {
  .c-list01.bg-gray {
    padding: 15px;
  }
}
.c-list01.bg-gray li {
  padding-left: min(36px, 2.635431918vw);
}
@media only screen and (max-width: 640px) {
  .c-list01.bg-gray li {
    padding-left: 19px;
  }
}
.c-list01.bg-gray li + li {
  margin-top: min(15px, 1.0980966325vw);
}
@media only screen and (max-width: 640px) {
  .c-list01.bg-gray li + li {
    margin-top: 10px;
  }
}

.c-list02 li {
  position: relative;
  padding-left: min(35px, 2.5622254758vw);
}
@media only screen and (max-width: 640px) {
  .c-list02 li {
    font-size: 14px;
    padding-left: 20px;
    line-height: 1.8;
  }
}
.c-list02 li::before {
  border: min(6px, 0.439238653vw) solid #13ACC4;
  border-radius: 100px;
  content: "";
  height: min(20px, 1.4641288433vw);
  width: min(20px, 1.4641288433vw);
  position: absolute;
  top: min(3px, 0.2196193265vw);
  left: 0;
}
@media only screen and (max-width: 640px) {
  .c-list02 li::before {
    border-width: 3px;
    height: 11px;
    width: 11px;
    top: 8px;
  }
}
.c-list02 li + li {
  margin-top: min(15px, 1.0980966325vw);
}
@media only screen and (max-width: 640px) {
  .c-list02 li + li {
    margin-top: 3px;
  }
}

.c-following-block {
  position: fixed;
  top: min(213px, 15.5929721816vw);
  right: 0;
}
@media only screen and (max-width: 640px) {
  .c-following-block {
    top: inherit;
    bottom: 0;
    left: 0;
    z-index: 900;
  }
}
.c-following-block .btn-wrap {
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 640px) {
  .c-following-block .btn-wrap {
    flex-direction: row;
  }
}
.c-following-block .btn-wrap a {
  color: #fff;
  font-size: min(14px, 1.0248901903vw);
  font-weight: bold;
  padding: 20px 15px;
  letter-spacing: 0;
  line-height: 1.18;
}
@media only screen and (min-width: 641px) {
  .c-following-block .btn-wrap a {
    border-radius: min(10px, 0.7320644217vw) 0 0 min(10px, 0.7320644217vw);
    border: 1px solid #0A616F;
    max-width: 44px;
  }
  .c-following-block .btn-wrap a:hover {
    background: #fff;
  }
  .c-following-block .btn-wrap a:hover.docs {
    color: #0A616F;
  }
  .c-following-block .btn-wrap a:hover.contact {
    color: #13ACC4;
  }
  .c-following-block .btn-wrap a + a {
    margin-top: min(5px, 0.3660322108vw);
  }
}
@media only screen and (max-width: 640px) {
  .c-following-block .btn-wrap a {
    font-size: 12px;
    text-align: center;
    width: calc(100% / 2);
  }
}
.c-following-block .btn-wrap a.docs {
  background: #0A616F;
}
.c-following-block .btn-wrap a.contact {
  background: #13ACC4;
  border-color: #13ACC4;
}

.c-link-box-wrap + .c-link-box-wrap {
  border-top: 2px solid #fff;
  margin-top: min(80px, 5.8565153734vw);
  padding-top: min(60px, 4.39238653vw);
}
@media only screen and (max-width: 640px) {
  .c-link-box-wrap + .c-link-box-wrap {
    margin-top: 40px;
    padding-top: 40px;
  }
}
@media only screen and (max-width: 640px) {
  .c-link-box-wrap + .c-link-list-wrap .c-title03 {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 640px) {
  .c-link-box-wrap .c-title02 {
    margin-bottom: 30px;
  }
}

.c-link-box {
  display: flex;
  flex-wrap: wrap;
}
.c-link-box > li {
  box-shadow: 0px 0px 14px rgba(181, 181, 181, 0.4);
  background: #fff;
  border-radius: 5px;
  width: calc((100% - min(60px, 4.39238653vw)) / 3);
}
@media only screen and (min-width: 641px) and (min-width: 901px) {
  .c-link-box > li:not(:nth-child(3n+1)) {
    margin-left: min(30px, 2.196193265vw);
  }
  .c-link-box > li:nth-child(n+4) {
    margin-top: min(40px, 2.9282576867vw);
  }
}
@media only screen and (min-width: 641px) and (max-width: 900px) {
  .c-link-box > li {
    width: calc((100% - min(30px, 2.196193265vw)) / 2);
  }
  .c-link-box > li:nth-child(2n+2) {
    margin-left: min(30px, 2.196193265vw);
  }
  .c-link-box > li:nth-child(n+3) {
    margin-top: min(40px, 2.9282576867vw);
  }
}
@media only screen and (max-width: 640px) {
  .c-link-box > li {
    box-shadow: 0px 0px 14px rgba(181, 181, 181, 0.4);
    width: 100%;
  }
  .c-link-box > li + li {
    margin-top: 20px;
  }
}
.c-link-box > li a {
  padding: min(30px, 2.196193265vw) min(30px, 2.196193265vw) min(20px, 1.4641288433vw);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}
@media only screen and (min-width: 641px) {
  .c-link-box > li a:hover .title {
    color: #13ACC4;
    transition-property: color;
    transition: 0.3s ease-in-out;
  }
  .c-link-box > li a:hover .c-btn01 {
    background: #13ACC4;
    color: #fff;
    transition-property: color;
    transition-duration: 0.3s ease-in-out;
  }
  .c-link-box > li a:hover .c-btn01::before {
    border-color: #fff;
  }
}
@media only screen and (max-width: 640px) {
  .c-link-box > li a {
    padding: 20px 15px;
  }
}
.c-link-box > li .tag {
  background: #13ACC4;
  border-radius: 100px;
  color: #fff;
  display: inline-block;
  font-size: min(13px, 0.9516837482vw);
  font-weight: bold;
  padding: min(4px, 0.2928257687vw) min(12px, 0.6588579795vw);
  letter-spacing: 0.07em;
}
@media only screen and (max-width: 640px) {
  .c-link-box > li .tag {
    font-size: 11px;
    padding: 4px 9px;
  }
}
.c-link-box > li .title {
  font-size: min(22px, 1.6105417277vw);
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.681;
  margin-top: min(15px, 1.0980966325vw);
  margin-bottom: min(15px, 1.0980966325vw);
  transition-property: color;
  transition: 0.3s ease-in-out;
}
@media only screen and (max-width: 640px) {
  .c-link-box > li .title {
    font-size: 20px;
    line-height: 1.5;
    margin-top: 9px;
    margin-bottom: 8px;
  }
}
.c-link-box > li .btn-wrap {
  margin-top: auto !important;
  margin-left: auto;
  padding-top: min(30px, 2.196193265vw);
}
@media only screen and (max-width: 640px) {
  .c-link-box > li .btn-wrap {
    padding-top: 19px;
  }
}

.c-link-list-wrap + .c-link-list-wrap {
  border-top: 2px solid #fff;
  margin-top: min(118px, 8.6383601757vw);
  padding-top: min(60px, 4.39238653vw);
}
@media only screen and (max-width: 640px) {
  .c-link-list-wrap + .c-link-list-wrap {
    margin-top: 40px;
    padding-top: 40px;
  }
}

.c-link-list {
  display: flex;
  flex-wrap: wrap;
}
.c-link-list li {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 14px rgba(181, 181, 181, 0.4);
  width: calc((100% - min(30px, 2.196193265vw)) / 2);
}
@media only screen and (min-width: 641px) {
  .c-link-list li:nth-child(2n+2) {
    margin-left: min(30px, 2.196193265vw);
  }
  .c-link-list li:nth-child(n+3) {
    margin-top: min(20px, 1.4641288433vw);
  }
  .c-link-list li .btn-wrap {
    min-width: 130px;
    margin-left: min(15px, 1.0980966325vw);
  }
  .c-link-list li .btn-wrap .c-btn01 {
    min-width: auto;
    padding: min(7px, 0.6588579795vw) min(27px, 1.9765739385vw) min(7px, 0.6588579795vw) min(17px, 1.2445095168vw);
    letter-spacing: 0;
  }
}
@media only screen and (max-width: 640px) {
  .c-link-list li {
    width: 100%;
  }
  .c-link-list li + li {
    margin-top: 10px;
  }
  .c-link-list li .btn-wrap::before, .c-link-list li .btn-wrap::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .c-link-list li .btn-wrap::before {
    border-radius: 100px;
    border: 1px solid #13ACC4;
    height: 25px;
    width: 25px;
    right: 15px;
  }
  .c-link-list li .btn-wrap::after {
    width: 4.5px;
    height: 4.5px;
    border-top: 1.5px solid #13ACC4;
    border-right: 1.5px solid #13ACC4;
    transform: rotate(45deg);
    right: 27px;
    top: -1px;
  }
  .c-link-list li .btn-wrap .c-btn01 {
    display: none;
  }
}
@media only screen and (min-width: 641px) {
  .c-link-list li a:hover .title {
    color: #13ACC4;
    transition: 0.3s ease-in-out;
    transition-property: color;
  }
  .c-link-list li a:hover .c-btn01 {
    background: #13ACC4;
    color: #fff;
    transition: 0.3s ease-in-out;
    transition-property: color;
  }
  .c-link-list li a:hover .c-btn01::before {
    border-color: #fff;
    transition: 0.3s ease-in-out;
    transition-property: color;
  }
}
.c-link-list li .inn {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: min(7px, 1.0980966325vw) min(15px, 1.0980966325vw);
  position: relative;
  min-height: min(75px, 5.4904831625vw);
  width: 100%;
}
@media only screen and (min-width: 641px) {
  .c-link-list li .inn:not(:has(.btn-wrap)) .title {
    width: calc(100% - min(80px, 5.8565153734vw));
  }
}
@media only screen and (max-width: 640px) {
  .c-link-list li .inn {
    min-height: 58px;
    padding: 4px 55px 4px 15px;
  }
}
.c-link-list li .icon {
  display: block;
  max-width: min(60px, 4.39238653vw);
  margin-right: min(20px, 1.4641288433vw);
}
@media only screen and (max-width: 640px) {
  .c-link-list li .icon {
    max-width: 50px;
    margin-right: 15px;
  }
}
.c-link-list li .title {
  font-size: min(18px, 1.317715959vw);
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.6;
  transition: 0.3s ease-in-out;
  transition-property: color;
  width: calc(100% - 130px - min(95px, 6.9546120059vw));
}
@media only screen and (max-width: 640px) {
  .c-link-list li .title {
    font-size: 14px;
    width: calc(100% - 65px);
  }
}
.c-link-list li .title .sub {
  display: block;
  font-weight: 400;
}

.c-lead-block .lead {
  margin-bottom: min(40px, 2.9282576867vw);
}
@media only screen and (max-width: 640px) {
  .c-lead-block .lead {
    font-size: 14px;
    margin: 0 0 35px;
  }
}

.mt-120_60 {
  margin-top: min(120px, 8.78477306vw);
}
@media only screen and (max-width: 640px) {
  .mt-120_60 {
    margin-top: 60px;
  }
}

.c-link-block .inner-block {
  padding-top: min(80px, 5.8565153734vw);
  padding-bottom: min(120px, 8.78477306vw);
}
@media only screen and (max-width: 640px) {
  .c-link-block .inner-block {
    padding-top: 40px;
    padding-bottom: 60px;
  }
}

.c-btn01 {
  background: #fff;
  border-radius: 100px;
  border: 1px solid #13ACC4;
  color: #13ACC4;
  display: block;
  font-size: min(14px, 1.0248901903vw);
  font-weight: bold;
  padding: min(7px, 0.5124450952vw) min(30px, 2.196193265vw) min(7px, 0.5124450952vw);
  position: relative;
  text-align: center;
  transition: 0.3s ease-in-out;
  width: 100%;
}
@media only screen and (min-width: 641px) {
  .c-btn01 {
    min-width: 178px;
  }
}
@media only screen and (max-width: 640px) {
  .c-btn01 {
    font-size: 14px;
    padding: 7px 30px;
    min-width: 178px;
  }
}
.c-btn01::before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  border-top: 2px solid #13ACC4;
  border-right: 2px solid #13ACC4;
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  bottom: 0;
  right: min(15px, 1.0980966325vw);
  margin: auto;
  transition: 0.3s ease-in-out;
}
@media only screen and (max-width: 640px) {
  .c-btn01::before {
    width: 6px;
    height: 6px;
    right: 15px;
  }
}

@media only screen and (min-width: 641px) {
  a.c-btn01:hover {
    background: #13ACC4;
    color: #fff;
  }
  a.c-btn01:hover::before {
    border-color: #fff;
    transition: 0.3s ease-in-out;
  }
}

.c-btn02 {
  background: #13ACC4;
  border-radius: 100px;
  border: 1px solid #fff;
  display: block;
  color: #fff;
  font-size: min(18px, 1.317715959vw);
  font-weight: bold;
  padding: min(18px, 1.317715959vw) min(30px, 2.196193265vw);
  position: relative;
  max-width: min(285px, 20.8638360176vw);
  margin: auto;
  text-align: center;
  width: 100%;
}
@media only screen and (min-width: 641px) {
  .c-btn02:hover {
    background: #fff;
    border: 1px solid #13ACC4;
    color: #13ACC4;
  }
  .c-btn02:hover::before {
    border-top: 2px solid #13ACC4;
    border-right: 2px solid #13ACC4;
  }
}
@media only screen and (max-width: 640px) {
  .c-btn02 {
    font-size: 14px;
    padding: 21px 30px;
    max-width: 200px;
    letter-spacing: 0;
  }
}
.c-btn02::before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  bottom: 0;
  right: min(30px, 2.196193265vw);
  margin: auto;
}
@media only screen and (max-width: 640px) {
  .c-btn02::before {
    width: 6px;
    height: 6px;
    right: 20px;
  }
}
.c-btn02.left {
  margin-left: 0;
  letter-spacing: 0;
}

.btn-wrap.l-flex {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 640px) {
  .btn-wrap.l-flex {
    flex-direction: column;
  }
}
.btn-wrap.l-flex .c-btn02 {
  margin: 0;
}
@media only screen and (min-width: 641px) {
  .btn-wrap.l-flex .c-btn02 + .c-btn02 {
    margin-left: min(20px, 1.4641288433vw);
  }
}
@media only screen and (max-width: 640px) {
  .btn-wrap.l-flex .c-btn02 + .c-btn02 {
    margin-top: 20px;
  }
}

.cta-block {
  background: #13ACC4;
  text-align: center;
}
.cta-block .inner-block {
  padding-top: min(40px, 2.9282576867vw);
  padding-bottom: min(46px, 3.3674963397vw);
}
@media only screen and (max-width: 640px) {
  .cta-block .inner-block {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media only screen and (min-width: 641px) {
  .cta-block .l-flex {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 641px) {
  .cta-block .l-flex .c-title-wrap {
    margin-right: min(40px, 2.9282576867vw);
    width: calc((100% - min(640px, 46.8521229868vw)));
  }
}
.cta-block .l-flex .c-title-wrap .main-title .main {
  letter-spacing: 0.08em;
}
.cta-block .l-flex .img-area {
  position: relative;
  top: min(20px, 1.4641288433vw);
}
@media only screen and (min-width: 641px) {
  .cta-block .l-flex .img-area {
    width: min(600px, 43.9238653001vw);
  }
}
.cta-block .l-flex .btn-wrap .l-flex {
  margin-bottom: min(38px, 2.7818448023vw);
}
@media only screen and (max-width: 640px) {
  .cta-block .l-flex .btn-wrap .l-flex {
    margin-bottom: 35px;
  }
}

.c-title-wrap .title-area {
  padding-bottom: 0 !important;
}
.c-title-wrap .title-area::before {
  content: none !important;
}
.c-title-wrap .main-title {
  font-size: inherit !important;
  line-height: 1.6 !important;
  letter-spacing: 0.06em !important;
  text-align: center;
  margin-top: 0 !important;
}
.c-title-wrap .main-title .sub {
  background: #fff;
  color: #13ACC4;
  display: inline-block;
  font-size: min(18px, 1.317715959vw);
  font-weight: bold;
  padding: min(5px, 0.3660322108vw) min(5px, 0.3660322108vw) min(5px, 0.3660322108vw) min(10px, 0.7320644217vw);
  line-height: 1.5;
}
@media only screen and (max-width: 640px) {
  .c-title-wrap .main-title .sub {
    font-size: 12px;
    padding: 5px 5px 5px 10px;
  }
}
.c-title-wrap .main-title .main {
  color: #fff;
  display: block;
  font-size: min(45px, 3.2942898975vw);
  font-weight: bold;
  margin-top: min(15px, 1.0980966325vw);
  letter-spacing: 0;
}
@media only screen and (max-width: 640px) {
  .c-title-wrap .main-title .main {
    font-size: min(40px, 10.6666666667vw);
    letter-spacing: 0.08em;
    line-height: 1.375;
    margin-top: 20px;
  }
}
.c-title-wrap .logo {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: min(10px, 0.7320644217vw);
}
@media only screen and (max-width: 640px) {
  .c-title-wrap .logo {
    margin-top: 25px;
  }
}
.c-title-wrap .logo .img {
  display: block;
  min-width: 312px;
}
@media only screen and (max-width: 640px) {
  .c-title-wrap .logo .img {
    min-width: 165px;
  }
}
.c-title-wrap .logo .img img {
  width: 100%;
}
.c-title-wrap .logo .text {
  color: #fff;
  font-size: min(13px, 0.9516837482vw);
  font-weight: bold;
  margin-left: min(17px, 1.2445095168vw);
}
@media only screen and (max-width: 640px) {
  .c-title-wrap .logo .text {
    font-size: 10px;
    margin-left: 10px;
  }
}
.c-title-wrap .btn-wrap {
  margin-top: min(45px, 3.2942898975vw);
}
@media only screen and (max-width: 640px) {
  .c-title-wrap .btn-wrap {
    margin-top: 40px;
  }
}
.c-title-wrap .btn-wrap .l-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: min(46px, 3.3674963397vw);
}
@media only screen and (max-width: 640px) {
  .c-title-wrap .btn-wrap .l-flex {
    margin-bottom: 34px;
  }
}
.c-title-wrap .btn-wrap .l-flex a {
  max-width: min(260px, 19.0336749634vw);
  margin: 0;
}
@media only screen and (max-width: 640px) {
  .c-title-wrap .btn-wrap .l-flex a {
    max-width: inherit;
    width: calc((100% - 10px) / 2);
  }
}
.c-title-wrap .btn-wrap .l-flex a + a {
  margin-left: min(30px, 2.196193265vw);
}
@media only screen and (max-width: 640px) {
  .c-title-wrap .btn-wrap .l-flex a + a {
    margin-left: 10px;
  }
}
.c-title-wrap .btn-wrap a {
  border-radius: 100px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border: 1px solid transparent;
  display: block;
  font-size: min(18px, 1.317715959vw);
  font-weight: bold;
  padding: min(17px, 1.3909224012vw) min(20px, 1.4641288433vw) min(17px, 1.317715959vw);
  position: relative;
  text-align: center;
  margin: auto;
  position: relative;
  letter-spacing: 0;
  width: 100%;
}
@media only screen and (min-width: 641px) {
  .c-title-wrap .btn-wrap a:hover.docs {
    background: #fff;
    border-color: #0A616F;
    color: #0A616F;
  }
  .c-title-wrap .btn-wrap a:hover.docs::before {
    border-color: #0A616F;
  }
  .c-title-wrap .btn-wrap a:hover.docs::after {
    background: url(/img/common/icon_docs_gr.svg) no-repeat center center;
    background-size: contain;
    transition: 0.3s ease-in-out;
  }
  .c-title-wrap .btn-wrap a:hover.contact {
    background: #13ACC4;
    border-color: #fff;
    color: #fff;
  }
  .c-title-wrap .btn-wrap a:hover.contact::before {
    border-color: #fff;
  }
  .c-title-wrap .btn-wrap a:hover.contact::after {
    background: url(/img/common/icon_mail_wh.svg) no-repeat center center;
    background-size: contain;
    transition: 0.3s ease-in-out;
  }
  .c-title-wrap .btn-wrap a:hover.online {
    background: #fff;
    border-color: #FFDB14;
  }
}
@media only screen and (max-width: 640px) {
  .c-title-wrap .btn-wrap a {
    font-size: min(14px, 3.7333333333vw);
    padding: 9px 20px 10px;
  }
}
.c-title-wrap .btn-wrap a.docs {
  background: #0A616F;
  color: #fff;
}
.c-title-wrap .btn-wrap a.docs::after {
  background: url(/img/common/icon_docs.svg) no-repeat center center;
  background-size: contain;
  height: min(21px, 1.5373352855vw);
  width: min(18px, 1.317715959vw);
}
@media only screen and (max-width: 640px) {
  .c-title-wrap .btn-wrap a.docs::after {
    height: 21px;
    width: 18px;
  }
}
.c-title-wrap .btn-wrap a.contact {
  background: #fff;
  color: #13ACC4;
}
@media only screen and (max-width: 640px) {
  .c-title-wrap .btn-wrap a.contact {
    padding-left: 30px;
  }
}
.c-title-wrap .btn-wrap a.contact::before {
  border-color: #13ACC4;
}
.c-title-wrap .btn-wrap a.contact::after {
  background: url(/img/common/icon_mail.svg) no-repeat center center;
  background-size: contain;
  height: min(15px, 1.0980966325vw);
  width: min(20px, 1.4641288433vw);
}
@media only screen and (max-width: 640px) {
  .c-title-wrap .btn-wrap a.contact::after {
    height: 15px;
    width: 20px;
  }
}
.c-title-wrap .btn-wrap a.online {
  background: #FFDB14;
  max-width: min(282px, 20.6442166911vw);
  padding: min(14px, 1.0248901903vw) min(20px, 1.4641288433vw) min(7px, 0.5124450952vw);
}
@media only screen and (min-width: 641px) and (max-width: 850px) {
  .c-title-wrap .btn-wrap a.online {
    max-width: min(250px, 29.4117647059vw);
  }
}
@media only screen and (max-width: 640px) {
  .c-title-wrap .btn-wrap a.online {
    max-width: inherit;
    width: 100%;
    padding: 7px 20px;
  }
}
.c-title-wrap .btn-wrap a.online::before {
  border-color: #333;
}
.c-title-wrap .btn-wrap a.online::after {
  background: url(/img/common/icon_cursor.svg) no-repeat center center;
  background-size: contain;
  height: min(18px, 1.317715959vw);
  width: min(18px, 1.317715959vw);
}
@media only screen and (max-width: 640px) {
  .c-title-wrap .btn-wrap a.online::after {
    height: 18px;
    width: 18px;
    left: 60px;
  }
}
.c-title-wrap .btn-wrap a.online .small {
  display: block;
  font-size: min(11px, 0.8052708638vw);
}
@media only screen and (max-width: 640px) {
  .c-title-wrap .btn-wrap a.online .small {
    font-size: 10px;
  }
}
.c-title-wrap .btn-wrap a.online .balloon {
  background: #FF6C00;
  max-width: 200px;
}
@media only screen and (max-width: 640px) {
  .c-title-wrap .btn-wrap a.online .balloon {
    max-width: 150px;
  }
}
.c-title-wrap .btn-wrap a.online .balloon::before {
  border-color: #FF6C00 transparent transparent transparent;
}
.c-title-wrap .btn-wrap a::before {
  content: "";
  width: 6px;
  height: 6px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(45deg);
  position: absolute;
  top: 0;
  bottom: 0;
  right: min(30px, 2.196193265vw);
  margin: auto;
}
@media only screen and (max-width: 640px) {
  .c-title-wrap .btn-wrap a::before {
    width: 7px;
    height: 7px;
    right: 18px;
  }
}
.c-title-wrap .btn-wrap a::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: min(30px, 2.196193265vw);
  margin: auto;
}
@media only screen and (max-width: 640px) {
  .c-title-wrap .btn-wrap a::after {
    left: 15px;
  }
}
.c-title-wrap .btn-wrap .balloon {
  background: #FFB100;
  border-radius: 100px;
  color: #fff;
  display: inline-block;
  font-size: min(16px, 1.1713030747vw);
  padding: min(3px, 0.2196193265vw) min(10px, 0.7320644217vw) min(4px, 0.2928257687vw);
  position: absolute;
  top: max(-25px, -1.8301610542vw);
  left: 0;
  right: 0;
  margin: auto;
  max-width: 114px;
}
@media only screen and (min-width: 641px) and (min-width: 1520px) {
  .c-title-wrap .btn-wrap .balloon {
    top: -25px;
  }
}
@media only screen and (min-width: 641px) and (max-width: 1000px) {
  .c-title-wrap .btn-wrap .balloon {
    top: max(-18px, -1.8vw);
  }
}
@media only screen and (max-width: 640px) {
  .c-title-wrap .btn-wrap .balloon {
    font-size: 12px;
    padding: 3px 10px 4px;
    top: -22px;
    max-width: 98px;
  }
}
.c-title-wrap .btn-wrap .balloon::before {
  content: "";
  border-style: solid;
  border-width: 10px 12px 0 12px;
  border-color: #FFB100 transparent transparent transparent;
  position: absolute;
  bottom: max(-5px, -0.3660322108vw);
  left: 0;
  right: 0;
  margin: auto;
  width: min(25px, 1.8301610542vw);
  height: min(10px, 0.7320644217vw);
}
@media only screen and (max-width: 640px) {
  .c-title-wrap .btn-wrap .balloon::before {
    border-width: 8px 10px 0 10px;
    width: 21px;
    height: 8px;
    bottom: -4px;
  }
}
.c-title-wrap .img-wrap {
  margin-top: 20px;
  margin-left: -20px;
  width: calc(100% + 40px);
}

/* -----------------------------------------------
* Modules エントリーポイント
-------------------------------------------------- */
/* -----------------------------------------------
* home Module
* homeページ用
-------------------------------------------------- */
.home .mv-block {
  background: #13ACC4;
  position: relative;
}
@media only screen and (max-width: 640px) {
  .home .mv-block {
    padding-bottom: 40px;
  }
}
.home .mv-block .inner-block {
  padding-top: min(40px, 2.9282576867vw);
  padding-bottom: min(37px, 2.7086383602vw);
}
@media only screen and (max-width: 640px) {
  .home .mv-block .inner-block {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.home .mv-block .main-img-l {
  position: absolute;
  bottom: min(25px, 3.513909224vw);
  left: max(-192px, -14.055636896vw);
  max-width: min(650px, 47.5841874085vw);
}
@media only screen and (min-width: 641px) and (min-width: 1520px) {
  .home .mv-block .main-img-l {
    left: -100px;
  }
}
@media only screen and (min-width: 641px) and (max-width: 930px) {
  .home .mv-block .main-img-l {
    left: max(-150px, -16.1290322581vw);
  }
}
.home .mv-block .main-img-r {
  position: absolute;
  bottom: min(25px, 1.8301610542vw);
  right: max(-232px, -16.9838945827vw);
  max-width: min(570px, 41.7276720351vw);
}
@media only screen and (min-width: 641px) and (min-width: 1520px) {
  .home .mv-block .main-img-r {
    right: -150px;
  }
}
@media only screen and (min-width: 641px) and (max-width: 930px) {
  .home .mv-block .main-img-r {
    right: max(-170px, -18.2795698925vw);
  }
}
.home .mv-block .intro-img {
  position: absolute;
  bottom: min(34px, 2.4890190337vw);
  right: min(68px, 4.9780380673vw);
  max-width: min(387px, 28.3308931186vw);
}
@media only screen and (max-width: 640px) {
  .home .mv-block .intro-img {
    position: relative;
    margin: auto;
    max-width: 335px;
    right: 0;
    bottom: 0;
  }
}
.home .logo-slide-block {
  text-align: center;
  padding: min(40px, 2.9282576867vw) 0 min(80px, 5.8565153734vw);
}
@media only screen and (max-width: 640px) {
  .home .logo-slide-block {
    padding: 44px 0 40px;
  }
}
.home .logo-slide-block .title {
  display: inline-block;
  font-size: min(18px, 1.317715959vw);
  font-weight: bold;
  text-align: center;
  letter-spacing: 0;
  padding-bottom: min(10px, 0.7320644217vw);
  margin-bottom: min(15px, 1.0980966325vw);
  position: relative;
}
@media only screen and (max-width: 640px) {
  .home .logo-slide-block .title {
    font-size: 18px;
    padding-bottom: 16px;
    margin-bottom: 18px;
  }
}
.home .logo-slide-block .title::before {
  background: url(/img/home/line.svg) no-repeat center center;
  background-size: contain;
  display: block;
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: 0;
}
.home .logo-slide-block .loopSliderWrap {
  position: absolute;
  top: 0;
  left: 0;
  height: 65px;
  overflow: hidden;
}
@media only screen and (max-width: 640px) {
  .home .logo-slide-block .loopSliderWrap {
    height: 40px;
  }
}
.home .logo-slide-block .loop-slider {
  position: relative;
  width: 100%;
  height: 65px;
  overflow: hidden;
  font-size: 0;
  line-height: 65px;
  text-align: left;
}
@media only screen and (max-width: 640px) {
  .home .logo-slide-block .loop-slider {
    height: 40px;
    line-height: 40px;
  }
}
.home .logo-slide-block .loop-slider * {
  margin: 0;
  padding: 0;
}
.home .logo-slide-block .loop-slider .loop-slider-list {
  display: inline-block;
  height: 65px;
  float: left;
  overflow: hidden;
  white-space: nowrap;
}
@media only screen and (max-width: 640px) {
  .home .logo-slide-block .loop-slider .loop-slider-list {
    height: 40px;
  }
}
.home .logo-slide-block .loop-slider .loop-slider-list li {
  display: inline-block;
  height: 100%;
  margin: 0 20px;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
}
.home .logo-slide-block .loop-slider .loop-slider-list li img {
  height: auto;
  vertical-align: middle;
}
@media only screen and (max-width: 640px) {
  .home .logo-slide-block .loop-slider .loop-slider-list li img {
    max-height: 40px;
  }
}
.home .point-link-block .inner-block {
  padding-top: min(60px, 4.39238653vw);
  padding-bottom: min(60px, 4.39238653vw);
}
@media only screen and (max-width: 640px) {
  .home .point-link-block .inner-block {
    padding-top: 40px;
    padding-bottom: 39px;
  }
}
.home .point-link-block .point-title {
  font-weight: bold;
  text-align: center;
  margin-bottom: min(56px, 4.0995607613vw);
}
@media only screen and (max-width: 640px) {
  .home .point-link-block .point-title {
    margin-bottom: 35px;
  }
}
.home .point-link-block .point-title .top {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: min(32px, 2.3426061493vw);
}
@media only screen and (max-width: 640px) {
  .home .point-link-block .point-title .top {
    font-size: 20px;
  }
}
.home .point-link-block .point-title .top img {
  margin-right: 5px;
  max-width: 269px;
}
@media only screen and (max-width: 640px) {
  .home .point-link-block .point-title .top img {
    max-width: 171px;
  }
}
.home .point-link-block .point-title .bottom {
  display: block;
  font-size: min(40px, 2.9282576867vw);
  letter-spacing: 0.07em;
}
@media only screen and (max-width: 640px) {
  .home .point-link-block .point-title .bottom {
    font-size: 26px;
  }
}
.home .point-link-block .point-link-list {
  display: flex;
  flex-wrap: wrap;
}
.home .point-link-block .point-link-list li {
  width: calc((100% - min(120px, 8.78477306vw)) / 5);
}
@media only screen and (min-width: 641px) {
  .home .point-link-block .point-link-list li:not(:first-child) {
    margin-left: min(30px, 2.196193265vw);
  }
}
@media only screen and (max-width: 640px) {
  .home .point-link-block .point-link-list li {
    width: calc((100% - 11px) / 2);
  }
  .home .point-link-block .point-link-list li:nth-child(2n+2) {
    margin-left: 11px;
  }
  .home .point-link-block .point-link-list li:nth-child(n+3) {
    margin-top: 40px;
  }
}
.home .point-link-block .point-link-list li a {
  display: block;
  position: relative;
  padding-bottom: min(30px, 2.196193265vw);
}
@media only screen and (min-width: 641px) {
  .home .point-link-block .point-link-list li a:hover .title .inn {
    color: #13ACC4;
    transition: 0.3s ease-in-out;
  }
}
@media only screen and (max-width: 640px) {
  .home .point-link-block .point-link-list li a {
    padding-bottom: 12px;
  }
}
.home .point-link-block .point-link-list li a::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  border-bottom: 2px solid #13ACC4;
  border-right: 2px solid #13ACC4;
  transform: rotate(45deg);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
@media only screen and (max-width: 640px) {
  .home .point-link-block .point-link-list li a::before {
    width: 8px;
    height: 8px;
  }
}
.home .point-link-block .point-link-list li .title {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 640px) {
  .home .point-link-block .point-link-list li .title {
    display: block;
    text-align: center;
  }
}
.home .point-link-block .point-link-list li .title .num {
  background: #13ACC4;
  border-radius: 100px;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: min(22px, 1.6105417277vw);
  font-weight: bold;
  position: relative;
  line-height: 1.5;
  height: min(48px, 3.513909224vw);
  width: min(48px, 3.513909224vw);
  padding: 0 0 min(2px, 0.1464128843vw) min(1px, 0.0732064422vw);
  z-index: 1;
}
@media only screen and (max-width: 640px) {
  .home .point-link-block .point-link-list li .title .num {
    font-size: 18px;
    height: 39px;
    width: 39px;
    margin: 0 auto 13px;
  }
}
.home .point-link-block .point-link-list li .title .num::before {
  border-radius: 3px;
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 12px 10px 0 10px;
  border-color: #13ACC4 transparent transparent transparent;
  position: absolute;
  height: 12px;
  width: 20px;
  bottom: 2px;
  right: -3px;
  transform: rotate(-50deg);
  z-index: -1;
}
@media only screen and (min-width: 641px) and (max-width: 1070px) {
  .home .point-link-block .point-link-list li .title .num::before {
    bottom: 1px;
    right: -5px;
  }
}
@media only screen and (min-width: 641px) and (max-width: 780px) {
  .home .point-link-block .point-link-list li .title .num::before {
    bottom: 1px;
    right: -6px;
  }
}
@media only screen and (max-width: 640px) {
  .home .point-link-block .point-link-list li .title .num::before {
    border-width: 10px 8px 0 8px;
    height: 10px;
    width: 16px;
    transform: rotate(0);
    right: 0;
    left: 0;
    margin: auto;
    bottom: -4px;
  }
}
.home .point-link-block .point-link-list li .title .inn {
  font-size: min(18px, 1.317715959vw);
  font-weight: bold;
  letter-spacing: 0;
  margin-left: min(10px, 0.7320644217vw);
  transition: 0.3s ease-in-out;
  width: calc(100% - min(58px, 4.2459736457vw));
}
@media only screen and (max-width: 640px) {
  .home .point-link-block .point-link-list li .title .inn {
    font-size: 16px;
    margin-left: 0;
    width: 100%;
  }
}
.home .point-link-block .point-link-list li .img {
  display: block;
  max-width: 120px;
  margin: 20px auto 0;
}
@media only screen and (max-width: 640px) {
  .home .point-link-block .point-link-list li .img {
    max-width: 100px;
    margin-top: 10px;
  }
}
.home .point-wrap-block {
  padding-bottom: min(100px, 7.3206442167vw);
}
@media only screen and (max-width: 640px) {
  .home .point-wrap-block {
    padding-bottom: 59px;
  }
}
@media only screen and (min-width: 641px) {
  .home .point-wrap-block .point-wrap:nth-child(odd) .l-flex .text-area {
    margin-right: min(60px, 4.39238653vw);
    width: calc((100% - min(585px, 42.8257686676vw)));
  }
  .home .point-wrap-block .point-wrap:nth-child(odd) .l-flex .img-area {
    width: min(525px, 38.4333821376vw);
  }
}
.home .point-wrap-block .point-wrap:nth-child(even) .inner-block {
  padding-top: min(129px, 9.4436310395vw);
}
@media only screen and (max-width: 640px) {
  .home .point-wrap-block .point-wrap:nth-child(even) .inner-block {
    padding-top: 30px;
  }
}
@media only screen and (min-width: 641px) {
  .home .point-wrap-block .point-wrap:nth-child(even) .l-flex {
    flex-direction: row-reverse;
  }
  .home .point-wrap-block .point-wrap:nth-child(even) .l-flex .img-area {
    margin-top: min(15px, 1.0980966325vw);
    width: min(495px, 36.2371888726vw);
  }
}
.home .point-wrap-block .point-wrap:nth-child(even) .l-flex .text-area {
  background: #fff;
  border-radius: 10px;
  padding: min(77px, 5.6368960469vw) min(60px, 4.39238653vw) min(55px, 4.0263543192vw);
}
@media only screen and (min-width: 641px) {
  .home .point-wrap-block .point-wrap:nth-child(even) .l-flex .text-area {
    margin-left: min(30px, 2.196193265vw);
    width: calc((100% - min(525px, 38.4333821376vw)));
  }
}
@media only screen and (max-width: 640px) {
  .home .point-wrap-block .point-wrap:nth-child(even) .l-flex .text-area {
    padding: 30px 20px;
  }
  .home .point-wrap-block .point-wrap:nth-child(even) .l-flex .text-area .text {
    margin-top: 15px;
  }
}
@media only screen and (min-width: 641px) {
  .home .point-wrap-block .point-wrap:nth-child(even) .l-flex .text-area .c-title05 .num {
    position: absolute;
    top: max(-69px, -5.0512445095vw);
  }
}
@media only screen and (min-width: 641px) and (max-width: 1000px) {
  .home .point-wrap-block .point-wrap:nth-child(even) .l-flex .text-area .c-title05 .num {
    top: max(-40px, -4vw);
  }
}
@media only screen and (min-width: 641px) {
  .home .point-wrap-block .point-wrap.point01 .text-area {
    margin-right: min(60px, 4.39238653vw);
    width: calc((100% - min(585px, 42.8257686676vw)));
    max-width: 600px;
  }
  .home .point-wrap-block .point-wrap.point01 .img-area {
    top: min(15px, 1.0980966325vw);
    position: relative;
    width: min(525px, 38.4333821376vw);
  }
}
.home .point-wrap-block .point-wrap.point03 .inner-block {
  padding-top: min(100px, 7.3206442167vw);
  padding-bottom: min(95px, 6.9546120059vw);
}
@media only screen and (max-width: 640px) {
  .home .point-wrap-block .point-wrap.point03 .inner-block {
    padding-top: 40px;
    padding-bottom: 39px;
  }
}
.home .point-wrap-block .point-wrap.point02 .inner-block, .home .point-wrap-block .point-wrap.point04 .inner-block {
  padding-bottom: min(60px, 4.39238653vw);
}
@media only screen and (max-width: 640px) {
  .home .point-wrap-block .point-wrap.point02 .inner-block, .home .point-wrap-block .point-wrap.point04 .inner-block {
    padding-bottom: 30px;
  }
}
@media only screen and (min-width: 641px) {
  .home .point-wrap-block .point-wrap.point04 .l-flex .img-area {
    margin-top: min(30px, 2.196193265vw);
  }
}
.home .point-wrap-block .point-wrap.point05 .inner-block {
  padding-top: min(100px, 7.3206442167vw);
  padding-bottom: min(99px, 7.2474377745vw);
}
@media only screen and (max-width: 640px) {
  .home .point-wrap-block .point-wrap.point05 .inner-block {
    padding-top: 40px;
    padding-bottom: 50px;
  }
}
@media only screen and (min-width: 641px) {
  .home .point-wrap-block .point-wrap.point05 .l-flex .text-area {
    margin-right: min(60px, 4.39238653vw);
    width: calc((100% - min(660px, 48.3162518302vw)));
  }
  .home .point-wrap-block .point-wrap.point05 .l-flex .img-area {
    margin-top: min(23px, 1.6837481698vw);
    width: min(600px, 43.9238653001vw);
  }
}
@media only screen and (max-width: 640px) {
  .home .point-wrap-block .point-wrap.point05 .text {
    margin-top: 47px;
  }
}
.home .point-wrap-block .point-wrap .inner-block {
  padding-top: min(80px, 5.8565153734vw);
  padding-bottom: min(108px, 7.906295754vw);
}
@media only screen and (max-width: 640px) {
  .home .point-wrap-block .point-wrap .inner-block {
    padding-top: 60px;
    padding-bottom: 58px;
  }
}
.home .point-wrap-block .point-wrap .l-flex {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 640px) {
  .home .point-wrap-block .point-wrap .l-flex {
    display: block;
  }
}
.home .point-wrap-block .point-wrap .l-flex .text-area {
  position: relative;
}
@media only screen and (max-width: 640px) {
  .home .point-wrap-block .point-wrap .l-flex .text-area {
    margin-bottom: 30px;
  }
}
.home .point-wrap-block .point-wrap .text {
  line-height: 2;
  margin-top: min(35px, 2.5622254758vw);
}
@media only screen and (max-width: 640px) {
  .home .point-wrap-block .point-wrap .text {
    line-height: 1.7142;
    margin-top: 25px;
  }
}
.home .point-wrap-block .point-wrap .support-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: min(35px, 2.5622254758vw);
}
@media only screen and (max-width: 640px) {
  .home .point-wrap-block .point-wrap .support-list {
    flex-direction: column;
    margin-top: 30px;
  }
}
.home .point-wrap-block .point-wrap .support-list li {
  position: relative;
  width: calc((100% - min(120px, 8.78477306vw)) / 3);
}
@media only screen and (max-width: 640px) {
  .home .point-wrap-block .point-wrap .support-list li {
    padding-top: 32px;
    padding-bottom: 29px;
    width: 100%;
  }
  .home .point-wrap-block .point-wrap .support-list li:first-child {
    padding-top: 0;
  }
  .home .point-wrap-block .point-wrap .support-list li:nth-child(2) .img {
    max-width: 156px;
    position: relative;
    right: 2px;
  }
  .home .point-wrap-block .point-wrap .support-list li:last-child {
    padding-bottom: 0;
  }
  .home .point-wrap-block .point-wrap .support-list li:last-child .img {
    max-width: 155px;
    position: relative;
    left: 2px;
  }
}
@media only screen and (min-width: 641px) {
  .home .point-wrap-block .point-wrap .support-list li + li {
    margin-left: min(60px, 4.39238653vw);
  }
}
.home .point-wrap-block .point-wrap .support-list li + li::before {
  background: rgba(19, 172, 196, 0.2);
  content: "";
  height: 100%;
  width: 1px;
  position: absolute;
  top: 0;
  left: max(-30px, -2.196193265vw);
}
@media only screen and (max-width: 640px) {
  .home .point-wrap-block .point-wrap .support-list li + li::before {
    left: 0;
    height: 1px;
    width: 100%;
  }
}
.home .point-wrap-block .point-wrap .support-list li .img {
  display: block;
  margin: auto;
  max-width: min(160px, 11.7130307467vw);
}
@media only screen and (max-width: 640px) {
  .home .point-wrap-block .point-wrap .support-list li .img {
    max-width: 152px;
  }
}
.home .point-wrap-block .point-wrap .support-list li .title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-size: min(20px, 1.4641288433vw);
  font-weight: bold;
  line-height: 1.7;
  text-align: center;
  margin-top: min(16px, 1.1713030747vw);
}
@media only screen and (max-width: 640px) {
  .home .point-wrap-block .point-wrap .support-list li .title {
    font-size: 16px;
    line-height: 1.6875;
    margin-top: 21px;
  }
}
.home .point-wrap-block .point-wrap .support-list li .text {
  font-size: min(14px, 1.0248901903vw);
  line-height: 1.7;
  margin-top: min(20px, 1.4641288433vw);
}
@media only screen and (max-width: 640px) {
  .home .point-wrap-block .point-wrap .support-list li .text {
    font-size: 14px;
    margin-top: 17px;
  }
}
.home .point-wrap-block .point-wrap .btn-wrap {
  margin-top: min(35px, 2.5622254758vw);
}
@media only screen and (max-width: 640px) {
  .home .point-wrap-block .point-wrap .btn-wrap {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 640px) {
  .home .point-wrap-block .point-wrap .btn-wrap .c-btn02.left {
    padding: 21px 25px 21px 15px;
    margin: auto;
  }
}
.home .point-wrap-block .banner-box {
  border: 1px solid #ddd;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  max-width: 810px;
  margin: auto;
}
@media only screen and (max-width: 640px) {
  .home .point-wrap-block .banner-box {
    max-width: calc(100% - 40px);
  }
}
.home .point-wrap-block .banner-box .img {
  width: min(315px, 23.0600292826vw);
}
@media only screen and (max-width: 640px) {
  .home .point-wrap-block .banner-box .img {
    width: 100%;
  }
  .home .point-wrap-block .banner-box .img img {
    border-radius: 10px 10px 0 0;
  }
}
.home .point-wrap-block .banner-box .text-area {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - min(315px, 23.0600292826vw));
}
@media only screen and (max-width: 640px) {
  .home .point-wrap-block .banner-box .text-area {
    padding: 27px 10px 40px;
    line-height: 1.5;
    width: 100%;
  }
  .home .point-wrap-block .banner-box .text-area .text {
    font-size: 18px;
    text-align: center;
  }
}
.home .point-wrap-block .banner-box .text-area .c-btn02 {
  min-width: min(285px, 20.8638360176vw);
  margin-top: min(27px, 1.9765739385vw);
}
@media only screen and (max-width: 640px) {
  .home .point-wrap-block .banner-box .text-area .c-btn02 {
    min-width: 200px;
    margin-top: 25px;
    padding: 20px 30px;
  }
}
.home .functions-block .inner-block {
  padding-top: min(70px, 5.1244509517vw);
  padding-bottom: min(100px, 7.3206442167vw);
}
@media only screen and (max-width: 640px) {
  .home .functions-block .inner-block {
    padding-top: 37px;
    padding-bottom: 58px;
  }
}
.home .functions-block .c-link-list {
  margin-top: min(55px, 4.0263543192vw);
  margin-bottom: min(60px, 4.39238653vw);
}
@media only screen and (max-width: 640px) {
  .home .functions-block .c-link-list {
    margin-top: 35px;
    margin-bottom: 37px;
  }
}
.home .intro-block .inner-block {
  padding-top: min(87px, 6.3689604685vw);
  padding-bottom: min(97px, 7.1010248902vw);
}
@media only screen and (max-width: 640px) {
  .home .intro-block .inner-block {
    padding-top: 57px;
    padding-bottom: 60px;
  }
}
.home .intro-block .intro-list {
  display: flex;
  flex-wrap: wrap;
  margin: min(55px, 4.0263543192vw) 0 min(60px, 4.39238653vw);
}
@media only screen and (max-width: 640px) {
  .home .intro-block .intro-list {
    flex-direction: column;
    margin: 36px 0;
  }
}
.home .intro-block .intro-list li {
  width: calc((100% - min(60px, 4.39238653vw)) / 3);
}
@media only screen and (min-width: 641px) {
  .home .intro-block .intro-list li:not(:nth-child(3n+1)) {
    margin-left: min(30px, 2.196193265vw);
  }
}
@media only screen and (max-width: 640px) {
  .home .intro-block .intro-list li {
    width: 100%;
  }
  .home .intro-block .intro-list li + li {
    margin-top: 28px;
  }
}
.home .intro-block .intro-list a:hover .title {
  color: #13ACC4;
  transition: 0.3s ease-in-out;
}
.home .intro-block .intro-list .img {
  border-radius: 10px;
}
.home .intro-block .intro-list .img img {
  border-radius: 10px;
}
.home .intro-block .intro-list .title {
  font-size: min(20px, 1.4641288433vw);
  font-weight: bold;
  letter-spacing: 0;
  margin: min(30px, 2.196193265vw) 0 min(30px, 2.196193265vw);
  transition: 0.3s ease-in-out;
}
@media only screen and (max-width: 640px) {
  .home .intro-block .intro-list .title {
    font-size: 18px;
    margin: 20px 0 15px;
    line-height: 1.7222;
  }
}
.home .intro-block .intro-list .text {
  color: #797979;
  font-size: min(15px, 1.0980966325vw);
  padding-left: min(20px, 1.4641288433vw);
  position: relative;
}
@media only screen and (max-width: 640px) {
  .home .intro-block .intro-list .text {
    font-size: 14px;
    padding-left: 20px;
  }
}
.home .intro-block .intro-list .text::before {
  background: #797979;
  content: "";
  display: block;
  height: 1px;
  width: 10px;
  position: absolute;
  top: min(12px, 0.878477306vw);
  left: 0;
}
@media only screen and (max-width: 640px) {
  .home .intro-block .intro-list .text::before {
    top: 10px;
  }
}

/* -----------------------------------------------
* home Module
* homeページ用
-------------------------------------------------- */
.purpose .purpose-block .inner-block {
  padding-top: min(84px, 6.149341142vw);
  padding-bottom: min(120px, 8.78477306vw);
}
@media only screen and (max-width: 640px) {
  .purpose .purpose-block .inner-block {
    padding-top: 40px;
    padding-bottom: 60px;
  }
}

/* -----------------------------------------------
* home Module
* homeページ用
-------------------------------------------------- */
@media only screen and (max-width: 640px) {
  .functions .c-lead-block {
    margin-bottom: 35px;
  }
}
.functions .functions-block .inner-block {
  padding-top: min(84px, 6.149341142vw);
  padding-bottom: min(120px, 8.78477306vw);
}
@media only screen and (max-width: 640px) {
  .functions .functions-block .inner-block {
    padding-top: 10.6666666667vw;
    padding-bottom: 16vw;
  }
}

/* -----------------------------------------------
* home Module
* homeページ用
-------------------------------------------------- */
.functions-detail .title-area, .functions-detail .list-area {
  position: relative;
}
.functions-detail .title-area::before, .functions-detail .list-area::before {
  background: #eee;
  content: "";
  height: 3px;
  width: calc(100% - min(48px, 3.513909224vw));
  position: absolute;
  bottom: 0;
}
@media only screen and (max-width: 640px) {
  .functions-detail .title-area::before, .functions-detail .list-area::before {
    width: calc(100% - 41px);
  }
}
.functions-detail .title-area::after, .functions-detail .list-area::after {
  background: url(/img/common/icon_line.svg) no-repeat center center;
  background-size: contain;
  content: "";
  display: block;
  height: 3px;
  width: 31px;
  position: absolute;
  bottom: 0;
}
@media only screen and (max-width: 640px) {
  .functions-detail .title-area::after, .functions-detail .list-area::after {
    height: 3px;
    width: 31px;
  }
}
.functions-detail .title-area {
  position: relative;
  padding-bottom: min(78px, 5.710102489vw);
}
@media only screen and (max-width: 640px) {
  .functions-detail .title-area {
    padding-bottom: 60px;
  }
}
.functions-detail .title-area::before {
  right: 0;
}
.functions-detail .title-area::after {
  left: min(2px, 0.1464128843vw);
}
@media only screen and (max-width: 640px) {
  .functions-detail .title-area::after {
    left: 0;
  }
}
.functions-detail .title-area .tag {
  background: #13ACC4;
  border-radius: 100px;
  color: #fff;
  display: inline-block;
  font-size: min(14px, 1.0248901903vw);
  padding: min(4px, 0.2928257687vw) min(12px, 0.878477306vw);
  text-align: center;
  letter-spacing: 0;
  margin: 0 min(10px, 0.7320644217vw) min(10px, 0.7320644217vw) 0;
}
@media only screen and (max-width: 640px) {
  .functions-detail .title-area .tag {
    font-size: 11px;
    padding: 4px 12px;
    margin: 0 10px 10px 0;
  }
}
.functions-detail .title-area .main-title {
  font-size: min(40px, 2.9282576867vw);
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.7;
  margin-top: min(12px, 0.878477306vw);
}
@media only screen and (max-width: 640px) {
  .functions-detail .title-area .main-title {
    font-size: 24px;
    margin-top: 7px;
  }
}
.functions-detail .list-area {
  padding: min(40px, 2.9282576867vw) 0 min(40px, 2.9282576867vw);
  position: relative;
}
@media only screen and (max-width: 640px) {
  .functions-detail .list-area {
    padding: 22px 0 36px;
  }
}
.functions-detail .list-area::before {
  left: 0;
}
.functions-detail .list-area::after {
  right: max(-3px, -0.2196193265vw);
  transform: scale(-1, -1);
}
@media only screen and (max-width: 640px) {
  .functions-detail .list-area::after {
    right: 0;
  }
}
.functions-detail .list-area .title {
  font-size: min(20px, 1.4641288433vw);
  font-weight: bold;
  letter-spacing: 0;
  margin-bottom: min(20px, 1.4641288433vw);
}
@media only screen and (max-width: 640px) {
  .functions-detail .list-area .title {
    font-size: 18px;
    margin-bottom: 14px;
  }
}
.functions-detail .list-area .title .c-blue {
  font-size: min(30px, 2.196193265vw);
  display: inline-block;
  margin-right: min(10px, 0.7320644217vw);
}
@media only screen and (max-width: 640px) {
  .functions-detail .list-area .title .c-blue {
    font-size: 22px;
    margin-right: 0;
  }
}
@media only screen and (min-width: 641px) {
  .functions-detail .list-area .c-list02 {
    padding-left: min(22px, 1.6105417277vw);
  }
}
.functions-detail .list-area .c-list02 + .title {
  margin-top: min(37px, 2.7086383602vw);
}
@media only screen and (max-width: 640px) {
  .functions-detail .list-area .c-list02 + .title {
    margin-top: 19px;
  }
}
.functions-detail .main-area {
  padding: min(80px, 5.8565153734vw) 0 min(120px, 8.78477306vw);
  max-width: 1020px;
  margin: auto;
}
@media only screen and (max-width: 640px) {
  .functions-detail .main-area {
    padding: 40px 0 60px;
  }
}
.functions-detail .main-area .wrap + .wrap {
  margin-top: min(75px, 5.4904831625vw);
}
@media only screen and (max-width: 640px) {
  .functions-detail .main-area .wrap + .wrap {
    margin-top: 30px;
  }
}
@media only screen and (min-width: 641px) {
  .functions-detail .main-area .wrap .l-flex:nth-child(odd) {
    flex-direction: row-reverse;
  }
  .functions-detail .main-area .wrap .l-flex:nth-child(odd) .text-wrap {
    margin-left: min(100px, 7.3206442167vw);
    margin-right: 0;
  }
}
.functions-detail .main-area .wrap .l-flex + .l-flex {
  margin-top: min(65px, 4.7584187408vw);
}
.functions-detail .main-area .l-flex {
  display: flex;
  flex-wrap: wrap;
}
@media only screen and (max-width: 640px) {
  .functions-detail .main-area .l-flex {
    flex-direction: column-reverse;
  }
}
.functions-detail .main-area .l-flex .text-wrap {
  margin-right: min(100px, 7.3206442167vw);
  width: calc((100% - min(560px, 40.9956076135vw)));
}
@media only screen and (max-width: 640px) {
  .functions-detail .main-area .l-flex .text-wrap {
    margin-top: 30px;
    margin-right: 0;
    width: 100%;
  }
}
.functions-detail .main-area .l-flex .img-wrap {
  width: min(460px, 33.6749633968vw);
}
.functions-detail .main-area .l-flex .img-wrap img {
  width: 100%;
}
@media only screen and (max-width: 640px) {
  .functions-detail .main-area .l-flex .img-wrap {
    width: 100%;
  }
}
.functions-detail .main-area .text {
  line-height: 2;
  margin-top: min(28px, 2.0497803807vw);
}
@media only screen and (max-width: 640px) {
  .functions-detail .main-area .text {
    font-size: 14px;
    margin-top: 30px;
    line-height: 1.75;
  }
}
.functions-detail .main-area .text + .note {
  margin-top: min(18px, 1.317715959vw);
}
@media only screen and (max-width: 640px) {
  .functions-detail .main-area .text + .note {
    margin-top: 18px;
  }
}
.functions-detail .main-area .text + .c-title02 {
  margin-top: min(80px, 5.8565153734vw);
}
@media only screen and (max-width: 640px) {
  .functions-detail .main-area .text + .c-title02 {
    margin-top: 30px;
  }
}
.functions-detail .main-area .note {
  font-size: min(12px, 0.878477306vw);
  line-height: 1.666;
  position: relative;
  padding-left: min(21px, 1.5373352855vw);
  margin-top: min(20px, 1.4641288433vw);
}
@media only screen and (max-width: 640px) {
  .functions-detail .main-area .note {
    font-size: 12px;
    padding-left: 21px;
    margin-top: 20px;
  }
}
.functions-detail .main-area .note::before {
  content: "※";
  position: absolute;
  left: 0;
  top: 0;
}
.functions-detail .main-area .note + .text {
  margin-top: min(20px, 1.4641288433vw);
}
@media only screen and (max-width: 640px) {
  .functions-detail .main-area .note + .text {
    margin-top: 20px;
  }
}
.functions-detail .main-area .c-title02.small + .text {
  margin-top: 0;
}
.functions-detail .main-area .c-title04 + .text {
  margin-top: 22px;
}
.functions-detail .main-area .img-wrap {
  text-align: center;
}
.functions-detail .c-link-block .c-link-box-wrap + .c-link-list-wrap .c-title03 {
  margin-top: min(55px, 4.0263543192vw);
}
@media only screen and (max-width: 640px) {
  .functions-detail .c-link-block .c-link-box-wrap + .c-link-list-wrap .c-title03 {
    margin-top: 18px;
  }
}
.functions-detail .c-link-block .c-link-list-wrap .c-title03 {
  margin-top: 0;
}

/* -----------------------------------------------
* home Module
* homeページ用
-------------------------------------------------- */
.security .contents-block .inner-block {
  padding-bottom: min(120px, 8.78477306vw);
}
@media only screen and (max-width: 640px) {
  .security .contents-block .inner-block {
    padding-bottom: 60px;
  }
}
.security .title-area {
  position: relative;
  padding-bottom: min(71px, 5.1976573939vw);
}
@media only screen and (max-width: 640px) {
  .security .title-area {
    padding-bottom: 60px;
  }
}
.security .title-area::before {
  right: 0;
}
.security .title-area::after {
  left: min(2px, 0.1464128843vw);
}
@media only screen and (max-width: 640px) {
  .security .title-area::after {
    left: 0;
  }
}
.security .title-area .tag {
  background: #13ACC4;
  border-radius: 100px;
  color: #fff;
  display: inline-block;
  font-size: min(14px, 1.0248901903vw);
  padding: min(5px, 0.2928257687vw) min(12px, 0.878477306vw);
  text-align: center;
  letter-spacing: 0;
  margin: 0 min(10px, 0.7320644217vw) min(10px, 0.7320644217vw) 0;
}
@media only screen and (max-width: 640px) {
  .security .title-area .tag {
    font-size: 11px;
    padding: 5px 12px;
    margin: 0 10px 10px 0;
  }
}
.security .title-area .main-title {
  font-size: min(40px, 2.9282576867vw);
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.7;
  margin-top: min(12px, 0.878477306vw);
}
@media only screen and (max-width: 640px) {
  .security .title-area .main-title {
    font-size: 24px;
    margin-top: 7px;
  }
}
.security .main-area {
  max-width: 1020px;
  margin: auto;
}
.security .main-area .wrap + .wrap {
  margin-top: min(58px, 4.2459736457vw);
}
@media only screen and (max-width: 640px) {
  .security .main-area .wrap + .wrap {
    margin-top: 39px;
  }
}
@media only screen and (max-width: 640px) {
  .security .main-area .wrap .c-title02.small {
    margin-bottom: 28px;
  }
}
.security .main-area .wrap .text {
  line-height: 2;
}
@media only screen and (max-width: 640px) {
  .security .main-area .wrap .text {
    line-height: 1.7;
  }
}

/* -----------------------------------------------
* Page エントリーポイント
-------------------------------------------------- */