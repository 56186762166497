@use '../mixin/mixin' as *;
@use '../setting/setting' as *;

//チェックボックスリスト
.c-list01 {
  li {
    padding-left: min(30px, #{vw(30,1366)});
    position: relative;
    line-height: 1.5;
    @include sp {
      font-size:  14px;
      padding-left: 19px;
    }
    &::before {
      background: url(/img/common/check.svg) no-repeat center center;
      background-size: contain;
      content: "";
      height: min(20px, #{vw(20,1366)});
      width: min(21px, #{vw(21,1366)});
      position: absolute;
      top: min(2px, #{vw(2,1366)});
      left: 0;
      @include sp {
        height: 12px;
        width: 13px;
        top: 6px;
      }
    }
    +li {
      margin-top: min(10px, #{vw(10,1366)});
      @include sp {
        margin-top:  10px;
      }
    }
  }
  &.bg-gray {
    padding: min(22px, #{vw(22,1366)}) min(22px, #{vw(22,1366)});
    @include sp {
      padding: 15px;
    }
    li {
      padding-left: min(36px, #{vw(36,1366)});
      @include sp {
        padding-left: 19px;
      }
      +li {
        margin-top: min(15px, #{vw(15,1366)});
        @include sp {
          margin-top:  10px;
        }
      }
    }
  }
}

// 丸リスト
.c-list02{
  li {
    position: relative;
    padding-left: min(35px, #{vw(35,1366)});
    @include sp {
      font-size: 14px;
      padding-left: 20px;
      line-height: 1.8;
    }
    &::before {
      border: min(6px, #{vw(6,1366)}) solid $blue;
      border-radius: 100px;
      content: "";
      height: min(20px, #{vw(20,1366)});
      width: min(20px, #{vw(20,1366)});
      position: absolute;
      top: min(3px, #{vw(3,1366)});
      left: 0;
      @include sp {
        border-width: 3px;
        height: 11px;
        width: 11px;
        top: 8px;
      }
    }
    +li {
      margin-top: min(15px, #{vw(15,1366)});
      @include sp {
        margin-top: 3px;
      }
    }
  }
}