@use '../mixin/mixin' as *;
@use '../setting/setting' as *;

// c-btn01 - 白背景青文字
.c-btn01 {
  background: #fff;
  border-radius: 100px;
  border: 1px solid $blue;
  color: $blue;
  display: block;
  font-size: min(14px, #{vw(14,1366)});
  font-weight: bold;
  padding: min(7px, #{vw(7,1366)}) min(30px, #{vw(30,1366)}) min(7px, #{vw(7,1366)});
  position: relative;
  text-align: center;
  transition: $transition01;
  width: 100%;
  @include pc {
    min-width: 178px;
  }
  @include sp {
    font-size:  14px;
    padding:  7px 30px;
    min-width:  178px;
  }
  &::before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-top: 2px solid $blue;
    border-right: 2px solid $blue;
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: min(15px, #{vw(15,1366)});
    margin: auto;
    transition: $transition01;
    @include sp {
      width: 6px;
      height: 6px;
      right: 15px
    }
  }
}
a.c-btn01 {
  @include pc {
    &:hover {
      background: $blue;
      color: #fff;
      &::before {
        border-color: #fff;
        transition: $transition01;
      }
    }
  }
}

// c-btn02 - 青背景白文字
.c-btn02 {
  background: $blue;
  border-radius: 100px;
  border: 1px solid #fff;
  display: block;
  color: #fff;
  font-size:  min(18px, #{vw(18,1366)});
  font-weight: bold;
  padding:  min(18px, #{vw(18,1366)})  min(30px, #{vw(30,1366)});
  position: relative;
  max-width:  min(285px, #{vw(285,1366)});
  margin: auto;
  text-align: center;
  width: 100%;
  @include pc {
    &:hover {
      background: #fff;
      border: 1px solid $blue;
      color: $blue;
      &::before {
        border-top: 2px solid $blue;
        border-right: 2px solid $blue;
      }
    }
  }
  @include sp {
    font-size: 14px;
    padding: 21px 30px;
    max-width: 200px;
    letter-spacing: 0;
  }
  &::before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right:  min(30px, #{vw(30,1366)});
    margin: auto;
    @include sp {
      width: 6px;
      height: 6px;
      right: 20px;
    }
  }
  &.left {
    margin-left: 0;
    letter-spacing: 0;
  }
}

.btn-wrap {
  &.l-flex {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include sp {
      flex-direction: column;
    }
    .c-btn02 {
      margin: 0;
      +.c-btn02 {
        @include pc {
          margin-left: min(20px, #{vw(20,1366)});
        }
        @include sp {
          margin-top: 20px;
        }
      }
    }
  }
}