@use '../mixin/mixin' as *;
@use '../setting/setting' as *;

// CTA
.cta-block {
  background: $blue;
  text-align: center;
  .inner-block {
    padding-top: min(40px, #{vw(40,1366)});
    padding-bottom: min(46px, #{vw(46,1366)});
    @include sp {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
  .l-flex {
    @include pc {
      align-items: flex-end;
      display: flex;
      flex-wrap: wrap;
    }
    .c-title-wrap {
      @include pc {
        margin-right: min(40px, #{vw(40,1366)});
        width: calc((100% - min(640px, #{vw(640,1366)})));
      }
      .main-title {
        .main {
          letter-spacing: 0.08em;
        }
      }
    }
    .img-area {
      position: relative;
      top: min(20px, #{vw(20,1366)});
      @include pc {
        width: min(600px, #{vw(600,1366)});
      }
    }
    .btn-wrap {
      .l-flex {
        margin-bottom: min(38px, #{vw(38,1366)});
        @include sp {
          margin-bottom: 35px;
        }
      }
    }
  }
}

// CTA-title
.c-title-wrap {
  .title-area {
    padding-bottom: 0!important;
    &::before {
      content: none!important;
    }
  }
  .main-title {
    font-size: inherit!important;
    line-height: 1.6!important;
    letter-spacing: 0.06em!important;
    text-align: center;
    margin-top: 0!important;
    .sub {
      background: #fff;
      color: $blue;
      display: inline-block;
      font-size: min(18px, #{vw(18,1366)});
      font-weight: bold;
      padding: min(5px, #{vw(5,1366)}) min(5px, #{vw(5,1366)}) min(5px, #{vw(5,1366)}) min(10px, #{vw(10,1366)});
      line-height: 1.5;
      @include sp {
        font-size: 12px;
        padding: 5px 5px 5px 10px;
      }
    }
    .main {
      color: #fff;
      display: block;
      font-size: min(45px, #{vw(45,1366)});
      font-weight: bold;
      margin-top: min(15px, #{vw(15,1366)});
      letter-spacing: 0;
      @include sp {
        font-size: min(40px, #{vw(40,375)});
        letter-spacing: 0.08em;
        line-height: 1.375;
        margin-top: 20px;
      }
    }
  }
  .logo {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: min(10px, #{vw(10,1366)});
    @include sp {
      margin-top: 25px;
    }
    .img {
      display: block;
      min-width: 312px;
      @include sp {
        min-width: 165px;
      }
      img {
        width: 100%;
      }
    }
    .text {
      color: #fff;
      font-size: min(13px, #{vw(13,1366)});
      font-weight: bold;
      margin-left: min(17px, #{vw(17,1366)});
      @include sp {
        font-size: 10px;
        margin-left: 10px;
      }
    }
  }
  .btn-wrap {
    margin-top: min(45px, #{vw(45,1366)});
    @include sp {
      margin-top: 40px;
    }
    .l-flex {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin-bottom: min(46px, #{vw(46,1366)});
      @include sp {
        margin-bottom: 34px;
      }
      a {
        max-width: min(260px, #{vw(260,1366)});
        margin: 0;
        @include sp {
          max-width: inherit;
          width: calc((100% - 10px) / 2);
        }
        +a {
          margin-left: min(30px, #{vw(30,1366)});
          @include sp {
            margin-left: 10px;
          }
        }
      }
    }
    a {
      border-radius: 100px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border: 1px solid transparent;
      display: block;
      font-size: min(18px, #{vw(18,1366)});
      font-weight: bold;
      padding: min(17px, #{vw(19,1366)}) min(20px, #{vw(20,1366)}) min(17px, #{vw(18,1366)});
      position: relative;
      text-align: center;
      margin: auto;
      position: relative;
      letter-spacing: 0;
      width: 100%;
      @include pc {
        &:hover {
          &.docs {
            background: #fff;
            border-color: $green;
            color: $green;
            &::before {
              border-color: $green;
            }
            &::after {
              background: url(/img/common/icon_docs_gr.svg) no-repeat center center;
              background-size: contain;
              transition: $transition01;
            }
          }
          &.contact {
            background: $blue;
            border-color: #fff;
            color: #fff;
            &::before {
              border-color: #fff;
            }
            &::after {
              background: url(/img/common/icon_mail_wh.svg) no-repeat center center;
              background-size: contain;
              transition: $transition01;
            }
          }
          &.online {
            background: #fff;
            border-color: $yellow;
          }
        }
      }
      @include sp {
        font-size: min(14px, #{vw(14,375)});
        padding: 9px 20px 10px
      }
      &.docs {
        background: $green;
        color: #fff;
        &::after {
         background: url(/img/common/icon_docs.svg) no-repeat center center;
         background-size: contain;
         height: min(21px, #{vw(21,1366)});
         width: min(18px, #{vw(18,1366)});
         @include sp {
          height: 21px;
          width: 18px;
         }
        }
      }
      &.contact {
        background: #fff;
        color: $blue;
        @include sp {
          padding-left: 30px;
        }
        &::before {
          border-color: $blue;
        }
        &::after {
          background: url(/img/common/icon_mail.svg) no-repeat center center;
          background-size: contain;
          height: min(15px, #{vw(15,1366)});
          width: min(20px, #{vw(20,1366)});
          @include sp {
            height: 15px;
            width: 20px;
          }
        }
      }
      &.online {
        background: $yellow;
        max-width: min(282px, #{vw(282,1366)});
        padding: min(14px, #{vw(14,1366)}) min(20px, #{vw(20,1366)}) min(7px, #{vw(7,1366)});
        @include pc {
          @include max(850) {
            max-width: min(250px, #{vw(250,850)});
          }
        }
        @include sp {
          max-width: inherit;
          width: 100%;
          padding: 7px 20px;
        }
        &::before {
          border-color: #333;
        }
        &::after {
          background: url(/img/common/icon_cursor.svg) no-repeat center center;
          background-size: contain;
          height: min(18px, #{vw(18,1366)});
          width: min(18px, #{vw(18,1366)});
          @include sp {
            height: 18px;
            width: 18px;
            left: 60px;
          }
         }
        .small {
          display: block;
          font-size: min(11px, #{vw(11,1366)});
          @include sp {
            font-size: 10px;
          }
        }
        .balloon {
          background: $orange;
          max-width: 200px;
          @include sp {
            max-width: 150px;
          }
          &::before {
            border-color: $orange transparent transparent transparent;
          }
        }
      }
      &::before {
        content: "";
        width: 6px;
        height: 6px;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(45deg);
        position: absolute;
        top: 0;
        bottom: 0;
        right: min(30px, #{vw(30,1366)});
        margin: auto;
        @include sp {
          width: 7px;
          height: 7px;
          right: 18px;
        }
      }
      &::after {
         content: "";
         position: absolute;
         top: 0;
         bottom: 0;
         left: min(30px, #{vw(30,1366)});
         margin: auto;
         @include sp {
          left: 15px;
         }
      }
    }
    .balloon {
      background: #FFB100;
      border-radius: 100px;
      color: #fff;
      display: inline-block;
      font-size: min(16px, #{vw(16,1366)});
      padding: min(3px, #{vw(3,1366)}) min(10px, #{vw(10,1366)}) min(4px, #{vw(4,1366)});
      position: absolute;
      top: max(-25px, #{vw(-25,1366)});
      left: 0;
      right: 0;
      margin: auto;
      max-width: 114px;
      @include pc {
        @include min(1520) {
          top: -25px;
        }
        @include max(1000) {
          top: max(-18px, #{vw(-18,1000)});
        }
      }
      @include sp {
        font-size: 12px;
        padding: 3px 10px 4px;
        top: -22px;
        max-width: 98px;
      }
      &::before {
        content: "";
        border-style: solid;
        border-width: 10px 12px 0 12px;
        border-color: #FFB100 transparent transparent transparent;
        position: absolute;
        bottom: max(-5px, #{vw(-5,1366)});
        left: 0;
        right: 0;
        margin: auto;
        width: min(25px, #{vw(25,1366)});
        height: min(10px, #{vw(10,1366)});
        @include sp {
          border-width: 8px 10px 0 10px;
          width: 21px;
          height: 8px;
          bottom: -4px;
        }
      }
    }
  }
  .img-wrap {
    margin-top: 20px;
    margin-left: -20px;
    width: calc(100% + 40px);
  }
}