@use '../mixin/mixin' as *;
@use '../setting/setting' as *;


// 追従ボタン
.c-following-block {
  position: fixed;
  top: min(213px, #{vw(213,1366)});
  right: 0;
  @include sp {
    top: inherit;
    bottom: 0;
    left: 0;
    z-index: 900;
  }
  .btn-wrap {
    display: flex;
    flex-direction: column;
    @include sp {
      flex-direction: row;
    }
    a {
      color: #fff;
      font-size: min(14px, #{vw(14,1366)});
      font-weight: bold;
      padding: 20px 15px;
      letter-spacing: 0;
      line-height: 1.18;
      @include pc {
        border-radius: min(10px, #{vw(10,1366)}) 0 0 min(10px, #{vw(10,1366)});
        border: 1px solid $green;
        max-width: 44px;
        &:hover {
          background: #fff;
          &.docs {
            color: $green;
          }
          &.contact {
            color: $blue;
          }
        }
        +a {
          margin-top: min(5px, #{vw(5,1366)});
        }
      }
      @include sp {
        font-size: 12px;
        text-align: center;
        width: calc(100% / 2);
      }
      &.docs {
        background: $green;
      }
      &.contact {
        background: $blue;
        border-color: $blue;
      }
    }
  }
}

//リンクボックス
.c-link-box-wrap {
  +.c-link-box-wrap {
    border-top: 2px solid #fff;
    margin-top: min(80px, #{vw(80,1366)});
    padding-top: min(60px, #{vw(60,1366)});
    @include sp {
      margin-top: 40px;
      padding-top: 40px;
    }
  }
  +.c-link-list-wrap {
    .c-title03 {
      @include sp {
        margin-top: 30px;
      }
    }
  }
  .c-title02 {
    @include sp {
      margin-bottom: 30px;
    }
  }
}
.c-link-box {
  display: flex;
  flex-wrap: wrap;
  > li {
    box-shadow: 0px 0px 14px rgba(181, 181, 181, 0.4);
    background: #fff;
    border-radius: 5px;
    width: calc((100% - min(60px, #{vw(60,1366)})) / 3);
    @include pc {
      @include min(901) {
        &:not(:nth-child(3n+1)) {
          margin-left: min(30px, #{vw(30,1366)});
        }
        &:nth-child(n+4) {
          margin-top: min(40px, #{vw(40,1366)});
        }
      }
      @include max(900) {
        width: calc((100% - min(30px, #{vw(30,1366)})) / 2);
        &:nth-child(2n+2) {
          margin-left: min(30px, #{vw(30,1366)});
        }
        &:nth-child(n+3) {
          margin-top: min(40px, #{vw(40,1366)});
        }
      }
    }
    @include sp {
      box-shadow: 0px 0px 14px rgba(181, 181, 181, 0.4);
      width: 100%;
      +li {
        margin-top: 20px;
      }
    }
    a {
      padding:  min(30px, #{vw(30,1366)}) min(30px, #{vw(30,1366)}) min(20px, #{vw(20,1366)});
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
      @include pc {
        &:hover {
          .title {
            color: $blue;
            transition-property: color;
            transition: $transition01;
          }
          .c-btn01 {
            background: $blue;
            color: #fff;
            transition-property: color;
            transition-duration: $transition01;
            &::before {
              border-color: #fff;
            }
          }
        }
      }
      @include sp {
        padding: 20px 15px;
      }
    }
    .tag {
      background: $blue;
      border-radius: 100px;
      color: #fff;
      display: inline-block;
      font-size: min(13px, #{vw(13,1366)});
      font-weight: bold;
      padding: min(4px, #{vw(4,1366)}) min(12px, #{vw(9,1366)});
      letter-spacing: 0.07em;
      @include sp {
        font-size: 11px;
        padding: 4px 9px;
      }
    }
    .title {
      font-size: min(22px, #{vw(22,1366)});
      font-weight: bold;
      letter-spacing: 0;
      line-height: 1.681;
      margin-top: min(15px, #{vw(15,1366)});
      margin-bottom: min(15px, #{vw(15,1366)});
      transition-property: color;
      transition: $transition01;
      @include sp {
        font-size: 20px;
        line-height: 1.5;
        margin-top: 9px;
        margin-bottom: 8px;
      }
    }
    .btn-wrap {
      margin-top: auto!important;
      margin-left: auto;
      padding-top: min(30px, #{vw(30,1366)});
      @include sp {
        padding-top: 19px;
      }
    }
  }
}

//リンクリスト
.c-link-list-wrap {
  +.c-link-list-wrap {
    border-top: 2px solid #fff;
    margin-top: min(118px, #{vw(118,1366)});
    padding-top: min(60px, #{vw(60,1366)});
    @include sp {
      margin-top: 40px;
      padding-top: 40px;
    }
  }
}
.c-link-list {
  display: flex;
  flex-wrap: wrap;
  li {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 14px rgba(181, 181, 181, 0.4);
    width: calc((100% - min(30px, #{vw(30,1366)})) / 2);
    @include pc {
      &:nth-child(2n+2) {
        margin-left: min(30px, #{vw(30,1366)});
      }
      &:nth-child(n+3) {
        margin-top: min(20px, #{vw(20,1366)});
      }
      .btn-wrap {
        min-width: 130px;
        margin-left: min(15px, #{vw(15,1366)});
        .c-btn01 {
          min-width: auto;
          padding: min(7px, #{vw(9,1366)}) min(27px, #{vw(27,1366)}) min(7px, #{vw(9,1366)}) min(17px, #{vw(17,1366)});
          letter-spacing: 0;
        }
      }
    }
    @include sp {
      +li {
        margin-top: 10px;
      }
      width: 100%;
      .btn-wrap {
        &::before, &::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
        }
        &::before {
          border-radius: 100px;
          border: 1px solid $blue;
          height: 25px;
          width: 25px;
          right: 15px;
        }
        &::after {
          width: 4.5px;
          height: 4.5px;
          border-top: 1.5px solid $blue;
          border-right: 1.5px solid $blue;
          transform: rotate(45deg);
          right: 27px;
          top: -1px;
        }
        .c-btn01 {
          display: none;
        }
      }
    }
    a {
      @include pc {
        &:hover {
          .title {
            color: $blue;
            transition: $transition01;
            transition-property: color;
          }
          .c-btn01 {
            background: $blue;
            color: #fff;
            transition: $transition01;
            transition-property: color;
            &::before {
              border-color: #fff;
              transition: $transition01;
              transition-property: color;
            }
          }
        }
      }
    }
    .inn {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      padding: min(7px, #{vw(15,1366)}) min(15px, 1.0980966325vw);
      position: relative;
      min-height: min(75px, #{vw(75,1366)});
      width: 100%;
      @include pc {
        &:not(:has(.btn-wrap)) {
          .title {
            width: calc(100% - min(80px, #{vw(80,1366)}))
          }
        }
      }
      @include sp {
        min-height: 58px;
        padding: 4px 55px 4px 15px;
      }
    }
    .icon {
      display: block;
      max-width: min(60px, #{vw(60,1366)});
      margin-right: min(20px, #{vw(20,1366)});
      @include sp {
        max-width: 50px;
        margin-right: 15px;
      }
    }
    .title {
      font-size: min(18px, #{vw(18,1366)});
      font-weight: bold;
      letter-spacing: 0;
      line-height: 1.6;
      transition: $transition01;
      transition-property: color;
      width: calc(100% - 130px - min(95px, #{vw(95,1366)}));
      @include sp {
        font-size: 14px;
        width: calc(100% -  65px);
      }
      .sub {
        display: block;
        font-weight: 400;
      }
    }
  }
}

// リードエリア
.c-lead-block {
  .lead {
    margin-bottom: min(40px, #{vw(40,1366)});
    @include sp {
      font-size: 14px;
      margin: 0 0 35px;
    }
  }
}

// 余白調整
.mt-120_60 {
  margin-top: min(120px, #{vw(120,1366)});
  @include sp {
    margin-top: 60px;
  }
}

// 下層共通リンクブロック
.c-link-block {
  .inner-block {
    padding-top: min(80px, #{vw(80,1366)});
    padding-bottom: min(120px, #{vw(120,1366)});
    @include sp {
      padding-top: 40px;
      padding-bottom: 60px;
    }
  }
}