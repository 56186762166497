@use '../mixin/mixin' as *;
@use '../setting/setting' as *;

/* --------------------------------
c-header
----------------------------------- */
.c-header {
  position: absolute;
  transition: $transition01;
  width: 100%;
  z-index: 999;
  @include pc {
    @include min(871) {
      &.is-fixed {
        position: fixed;
        top: 0;
        transform: translateY(0)!important;
        .sub {
          display: none;
        }
        .header-top {
          padding: min(22px, #{vw(22,1366)}) min(15px, #{vw(15,1366)}) min(7px, #{vw(7,1366)});
          @include max(1135) {
            padding-top: min(25px, #{vw(25,1366)});
          }
        }
        .header-bottom {
          display: none;
        }
        .c-menu {
          margin-left: min(50px, #{vw(50,1366)});
          @include max(1135) {
            margin-left: min(20px, #{vw(20,1135)});
          }
          > li {
            > a {
              color: $green;
              font-size: min(13px, #{vw(13,1366)});
              padding: 0;
              &:hover {
                color: $blue;
              }
            }
            +li {
              margin-left: min(25px, #{vw(25,1366)});
              @include max(1135) {
                margin-left: min(15px, #{vw(25,1135)});
              }
            }
            .balloon {
              top: min(2px, #{vw(2,1366)});
              bottom: 0;
              left: max(-95px, #{vw(-95,1366)});
              margin: auto;
              padding: min(3px, #{vw(3,1366)}) min(7px, #{vw(7,1366)});
              @include max(1135) {
                top: max(-20px, #{vw(-20,1366)});
                left: max(-70px, #{vw(-70,1366)});
                bottom: inherit;
              }
              &::after {
                border-width:  min(5px, #{vw(5,1366)}) 0  min(5px, #{vw(5,1366)}) min(11px, #{vw(11,1366)});
                height: min(11px, #{vw(11,1366)});
                width: min(11px, #{vw(11,1366)});
                position: absolute;
                bottom: 0;
                top: 0;
                margin: auto;
                transform: rotate(0);
                @include max(1135) {
                  border-width:  min(3px, #{vw(3,1135)}) 0  min(3px, #{vw(3,1135)}) min(6px, #{vw(9,1135)});
                  height: min(6px, #{vw(6,1135)});
                  width: min(6px, #{vw(6,1135)});
                  right: max(-2px, #{vw(-2,1135)});
                  bottom: max(-10px, #{vw(-10,1135)});
                  transform: rotate(35deg);
                }
              }
            }
            &:not(:first-child), &:first-child {
              &::before {
                content: none;
              }
            }
          }
        }
        .header-btn_wrap {

          .balloon {
            display: block;
          }
        }
      }
    }
  }
  @include max(870) {
    position: fixed;
  }
  .header-top {
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: min(22px, #{vw(22,1366)}) min(42px, #{vw(42,1366)}) min(7px, #{vw(7,1366)}) min(40px, #{vw(40,1366)});
    @include max(870) {
      border-bottom: 1px solid #E3E3E3;
      padding: vw(18,870) vw(20,870);
      position: fixed;
      top: 0;
      width: 100%;
      @include sp {
        padding: 19px 20px 17px;
      }
      &.is-open {
        background: $green;
        a {
          .is-close {
            display: none;
          }
          .is-open {
            display: block;
          }
        }
      }
    }
  }
  .header-bottom {
    background: $green;
    padding: min(17px, #{vw(17,1366)}) 0 min(18px, #{vw(18,1366)});
    position: relative;
    @include max(870) {
      position: fixed;
      top: vw(59,870);
      left: 0;
      height: 100%;
      width: 100%;
      padding: 20px 20px 20px;
      z-index: 990;
      display: none;
      @include sp {
        top: 60px;
      }
      &.is-open {
        display: block;
      }
    }
  }
  .logo {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    @include max(870) {
      position: relative;
      z-index: 999;
    }
    a {
      max-width: min(164px, #{vw(164,1366)});
      @include max(870) {
        max-width: vw(127,870);
        @include sp {
          max-width: 127px;
        }
      }
      .is-open {
        display: none;
      }
    }
    .sub {
      font-size: min(11px, #{vw(11,1366)});
      font-weight: 400;
      margin-left: min(20px, #{vw(20,1366)});
      @include max(870) {
        display: none;
      }
    }
  }
  .header-btn_wrap {
    @include pc {
      @include min(871) {
        display: flex;
        flex-wrap: wrap;
        .balloon {
          background: #FFB100;
          border-radius: 100px;
          color: #fff;
          font-size: min(10px, #{vw(10,1366)});
          font-weight: bold;
          padding: min(3px, #{vw(3,1366)}) min(5px, #{vw(5,1366)}) min(4px, #{vw(4,1366)}) min(6px, #{vw(6,1366)});
          position: absolute;
          top: max(-18px, #{vw(-18,1366)});
          left: 0;
          right: 0;
          margin: auto;
          max-width: 61px;
          @include max(1135) {
            padding: 0 min(4px, #{vw(4,1135)}) 0 min(5px, #{vw(5,1135)});
          }
          &::before {
            border-style: solid;
            border-width: min(6px, #{vw(6,1366)}) min(5px, #{vw(5,1366)}) 0 min(5px, #{vw(5,1366)});
            border-color: #FFB100 transparent transparent transparent;
            content: "";
            display: inline-block;
            height: min(6px, #{vw(6,1366)});
            width: min(10px, #{vw(10,1366)});
            position: absolute;
            left: 0;
            right: 0;
            margin: auto;
            bottom: max(-4px, #{vw(-4,1366)});
            right: max(-7px, #{vw(-5,1366)});
          }
        }
      }
    }
    @include max(870) {
      display: none!important;
      margin-top: 30px;
      .balloon {
        display: none;
      }
    }
    li {
      @include pc {
        @include min(871) {
          width: calc((100% - min(6px, #{vw(6,1366)})) / 3);
          &:not(:first-child) {
            margin-left: min(3px, #{vw(3,1366)});
          }
        }
      }
      @include max(870) {
        +li {
          margin-top: 15px;
        }
      }
    }
    a {
      border-radius: 100px;
      display: block;
      font-size: min(14px, #{vw(14,1366)});
      font-weight: bold;
      padding: min(8px, #{vw(8,1366)}) min(10px, #{vw(10,1366)});
      text-align: center;
      position: relative;
      width: 100%;
      @include pc {
        @include min(871) {
          max-width: min(150px, #{vw(150,1366)});
          min-width: min(150px, #{vw(150,1366)});
        }
        &:hover {
          &.docs {
            background: #fff;
            color: $green;
            .inn {
              &::before {
                background: url(/img/common/icon_docs_gr.svg) no-repeat center center;
                background-size: contain;
                transition: $transition01;
              }
            }
          }
          &.contact {
            background: $blue;
            color: #fff;
            .inn {
              &::before {
                background: url(/img/common/icon_mail_wh.svg) no-repeat center center;
                background-size: contain;
                transition: $transition01;
              }
            }
          }
          &.online {
            background: #fff;
          }
        }
      }
      @include max(870) {
        font-size: 14px;
        padding: 8px 10px;
      }
      .inn {
        position: relative;
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 1px;
          bottom: 0;
          left: 0;
          margin: auto;
        }
      }
      &.docs {
        background: $green;
        border: 1px solid $green;
        color: #fff;
        .inn {
          padding-left: min(16px, #{vw(16,1366)});
          @include max(870) {
            padding-left: 16px;
          }
          &::before {
            background: url(/img/common/icon_docs.svg) no-repeat center center;
            background-size: contain;
            height: min(14px, #{vw(14,1366)});
            width: min(11px, #{vw(11,1366)});
            transition: $transition01;
            @include max(870) {
              height: 17px;
              width: 14px;
            }
          }
        }
      }
      &.contact {
        background: #fff;
        border: 1px solid $blue;
        color: $blue;
        .inn {
          padding-left: min(23px, #{vw(23,1366)});
          @include max(870) {
            padding-left: 23px;
          }
          &::before {
            background: url(/img/common/icon_mail.svg) no-repeat center center;
            background-size: contain;
            height: min(13px, #{vw(13,1366)});
            width: min(18px, #{vw(18,1366)});
            transition: $transition01;
            @include max(870) {
              height: 13px;
              width: 18px;
            }
          }
        }
      }
      &.online {
        background: $yellow;
        border: 1px solid $yellow;
        @include pc {
          min-width: min(160px, #{vw(160,1366)});
        }
        .inn {
          padding-left: min(16px, #{vw(16,1366)});
          @include max(870) {
            padding-left: 16px;
          }
          &::before {
            background: url(/img/common/icon_cursor.svg) no-repeat center center;
            background-size: contain;
            height: min(12px, #{vw(12,1366)});
            width: min(12px, #{vw(12,1366)});
            @include max(870) {
              height: 12px;
              width: 12px;
            }
          }
        }
        .balloon {
          background: $orange;
          border-color: $orange;
          color: #fff;
          @include max(870) {
            left: -20px;
          }
          &::before {
            border-color: $orange transparent transparent transparent;
          }
        }
      }
    }
  }
  .c-menu {
    @include pc {
      @include min(871) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    > li {
      position: relative;
      @include pc {
        &:not(.hv-li) {
          &::before {
            background: #fff;
            border-radius: 100px;
            content: "";
            display: block;
            height: 12px;
            width: 1px;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            @include max(870) {
              content: none;
            }
          }
        }
        &:first-child {
          &::before {
            @include min(871) {
              right: 0;
              left: inherit;
            }
          }
        }
      }
      @include max(870) {
        +li {
          border-top: 1px solid #ddd;
        }
      }
      > a {
        color: #fff;
        font-size: min(15px, #{vw(15,1366)});
        font-weight: bold;
        padding: 0 min(40px, #{vw(40,1366)});
        @include pc {
          &:hover {
            color: $yellow;
          }
        }
        @include max(870) {
          display: block;
          font-size: 20px;
          padding: 29px 0 29px 23px;
          position: relative;
          &::before {
            content: "";
            width: 6px;
            height: 6px;
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            transform: rotate(45deg);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
          }
        }
      }
      .balloon {
        background: $orange;
        border-radius: min(20px, #{vw(20,1366)});
        color: #fff;
        display: inline-block;
        font-size: min(10px, #{vw(10,1366)});
        font-weight: bold;
        padding: min(4px, #{vw(4,1366)}) min(11px, #{vw(11,1366)});
        letter-spacing: 0;
        position: absolute;
        top: max(-10px, #{vw(-10,1366)});
        left: max(-58px, #{vw(-58,1366)});
        @include max(1060) {
          left: max(-65px, #{vw(-65,1366)});
        }
        @include max(870) {
          display: none;
        }
        &::after {
          border-style: solid;
          border-width: min(5px, #{vw(5,1366)}) 0 min(5px, #{vw(5,1366)}) min(11px, #{vw(11,1366)});
          border-color: transparent transparent transparent $orange;
          content: "";
          display: inline-block;
          height: min(11px, #{vw(7,1366)});
          width: min(11px, #{vw(7,1366)});
          position: absolute;
          bottom: 0;
          right: max(-4px, #{vw(-4,1366)});
          @include pc {
            transform: rotate(36deg);
          }
          @include max(870) {
            height: 5px;
            width: 5px;
            bottom: -5px;
            right: 0;
            left: 0;
            margin: auto;
            transform: rotate(90deg);
          }
        }
      }
      &.hv-li {
        position: static;
        @include pc {
          &:hover {
            .hover-menu {
              opacity: 1;
              visibility: visible;
              transition: $transition01;
            }
          }
        }
      }
    }
  }
  .hover-menu {
    background: $gray;
    position: absolute;
    top: min(60px, #{vw(60,1366)});
    left: 0;
    width: 100%;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: $transition01;
    .inner-block {
      padding: min(59px, #{vw(59,1366)}) min(16px, #{vw(16,1366)}) min(59px, #{vw(59,1366)});
      display: flex;
    }
    .menu-title {
      color: $green;
      font-size: min(20px, #{vw(20,1366)});
      font-weight: bold;
      letter-spacing: 0;
      margin-right: min(128px, #{vw(128,1366)});
      white-space: nowrap;
    }
    .sub-menu {
      display: flex;
      flex-wrap: wrap;
      li {
        width: calc((100% - min(100px, #{vw(100,1366)})) / 3);
        @include pc {
          &:nth-child(3n+1) {
            width: min(350px, #{vw(350,1366)});
          }
          &:nth-child(3n+2) {
            max-width: min(270px, #{vw(270,1366)});
          }
        }
        &:nth-child(n+4) {
          margin-top: min(30px, #{vw(30,1366)});
        }
        a {
          color: #333;
          font-size: min(16px, #{vw(16,1366)});
          font-weight: 400;
          position: relative;
          padding-left: min(14px, #{vw(14,1366)});
          &::before {
            content: "";
            width: min(6px, #{vw(6,1366)});
            height: min(6px, #{vw(6,1366)});
            border-top: 1px solid $blue;
            border-right: 1px solid $blue;
            transform: rotate(45deg);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
          }
          @include pc{
            &:hover {
              color: $blue;
            }
          }
        }
      }
    }
  }
}

/* --------------------------------
バーガーメニュー
----------------------------------- */
.btn-menu {
  @include pc {
    @include min(871) {
      display: none;
    }
  }
  @include max(870) {
    background: $green;
    width: vw(65,870);
    height: vw(60,870);
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    @include sp {
      width: 65px;
      height: 60px;
    }
    .inn {
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: vw(20,870);
      height: vw(19,870);
      @include sp {
        width: 20px;
        height: 19px;;
      }
    }
    .line:nth-of-type(2)::after {
      top: 0;
      content: '';
    }
    .line,
    .line:nth-of-type(2)::after {
      background: #fff;
      display: inline-block;
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      transition: $transition01;
      transition-property: transform;
    }
    .line:nth-of-type(1) {
      top: 0;
      transform-origin: right center;
    }
    .line:nth-of-type(3) {
      top: vw(16,870);
      transform-origin: left center;
      @include sp {
        top: 16px;
      }
    }
    .line:nth-of-type(2) {
      top: vw(8,870);
      @include sp {
        top: 8px;
      }
    }

    .line:nth-of-type(1),
    .line:nth-of-type(3) {
      transition: .2s;
      transition-property: transform;
      transition-delay: .3s;
    }

    &.is-open .line:nth-of-type(1),
    &.is-open .line:nth-of-type(3) {
      transform: scaleX(0) translate3d(0,0,0);
      transition-delay: 0s;
    }
    &.is-open .line:nth-of-type(2),
    &.is-open .line:nth-of-type(2)::after {
      transition-delay: .3s;
    }
    &.is-open .line:nth-of-type(2) {
      transform: rotate(45deg) translate3d(0,0,0);
    }
    &.is-open .line:nth-of-type(2)::after {
      transform: rotate(90deg) translate3d(0,0,0);
    }
  }
}
