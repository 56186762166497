/* -----------------------------------------------
* home Module
* homeページ用
-------------------------------------------------- */
@use '../../../mixin/mixin' as *;
@use '../../../setting/setting' as *;

.functions-detail {
  .title-area, .list-area  {
    position: relative;
    &::before {
      background: #eee;
      content: "";
      height: 3px;
      width: calc(100% - min(48px, #{vw(48,1366)}));
      position: absolute;
      bottom: 0;
      @include sp {
        width: calc(100% - 41px);
      }
    }
    &::after {
      background: url(/img/common/icon_line.svg) no-repeat center center;
      background-size: contain;
      content: "";
      display: block;
      height: 3px;
      width: 31px;
      position: absolute;
      bottom: 0;
      @include sp {
        height: 3px;
        width: 31px;
      }
    }
  }
  .title-area {
    position: relative;
    padding-bottom: min(78px, #{vw(78,1366)});
    @include sp {
      padding-bottom: 60px;
    }
    &::before {
      right: 0;
    }
    &::after {
      left: min(2px, #{vw(2,1366)});
      @include sp {
        left: 0;
      }
    }
    .tag {
      background: $blue;
      border-radius: 100px;
      color: #fff;
      display: inline-block;
      font-size: min(14px, #{vw(14,1366)});
      padding: min(4px, #{vw(4,1366)}) min(12px, #{vw(12,1366)});
      text-align: center;
      letter-spacing: 0;
      margin: 0 min(10px, #{vw(10,1366)}) min(10px, #{vw(10,1366)}) 0;
      @include sp {
        font-size: 11px;
        padding: 4px 12px;
        margin: 0 10px 10px 0;
      }
    }
    .main-title {
      font-size: min(40px, #{vw(40,1366)});
      font-weight: bold;
      letter-spacing: 0;
      line-height: 1.7;
      margin-top: min(12px, #{vw(12,1366)});
      @include sp{
        font-size: 24px;
        margin-top: 7px;
      }
    }
  }
  .list-area {
    padding: min(40px, #{vw(40,1366)}) 0 min(40px, #{vw(40,1366)});
    position: relative;
    @include sp {
      padding: 22px 0 36px;
    }
    &::before {
      left: 0;
    }
    &::after {
      right: max(-3px, #{vw(-3,1366)});
      transform: scale(-1,-1);
      @include sp {
        right: 0;
      }
    }
    .title {
      font-size: min(20px, #{vw(20,1366)});
      font-weight: bold;
      letter-spacing: 0;
      margin-bottom: min(20px, #{vw(20,1366)});
      @include sp {
        font-size: 18px;
        margin-bottom: 14px;
      }
      .c-blue {
        font-size: min(30px, #{vw(30,1366)});
        display: inline-block;
        margin-right: min(10px, #{vw(10,1366)});
        @include sp {
          font-size: 22px;
          margin-right: 0;
        }
      }
    }
    .c-list02 {
      @include pc {
        padding-left: min(22px, #{vw(22,1366)});
      }
      +.title {
        margin-top: min(37px, #{vw(37,1366)});
        @include sp {
          margin-top: 19px;
        }
      }
    }
  }
  .main-area {
    padding: min(80px, #{vw(80,1366)}) 0 min(120px, #{vw(120,1366)});
    max-width: 1020px;
    margin: auto;
    @include sp {
      padding: 40px 0 60px;
    }
    .wrap {
      +.wrap {
        margin-top: min(75px, #{vw(75,1366)});
        @include sp {
          margin-top: 30px;
        }
      }
      .l-flex {
        @include pc {
          &:nth-child(odd) {
            flex-direction: row-reverse;
            .text-wrap {
              margin-left: min(100px, #{vw(100,1366)});
              margin-right: 0;
            }
          }
        }
        +.l-flex {
          margin-top: min(65px, #{vw(65,1366)});
        }
      }
    }
    .l-flex {
      display: flex;
      flex-wrap: wrap;
      @include sp {
        flex-direction: column-reverse;
      }
      .text-wrap {
        margin-right: min(100px, #{vw(100,1366)});
        width: calc((100% - min(560px, #{vw(560,1366)})));
        @include sp {
          margin-top: 30px;
          margin-right: 0;
          width: 100%;
        }
      }
      .img-wrap {
        width: min(460px, #{vw(460,1366)});
        img {
          width: 100%;
        }
        @include sp {
          width: 100%;
        }
      }
    }
    .text {
      line-height: 2;
      margin-top: min(28px, #{vw(28,1366)});
      @include sp {
        font-size: 14px;
        margin-top: 30px;
        line-height: 1.75;
      }
      +.note {
        margin-top: min(18px, #{vw(18,1366)});
        @include sp {
          margin-top: 18px;
        }
      }
      +.c-title02 {
        margin-top: min(80px, #{vw(80,1366)});
        @include sp {
          margin-top: 30px;
        }
      }
    }
    .note {
      font-size: min(12px, #{vw(12,1366)});
      line-height: 1.666;
      position: relative;
      padding-left: min(21px, #{vw(21,1366)});
      margin-top: min(20px, #{vw(20,1366)});
      @include sp {
        font-size: 12px;
        padding-left: 21px;
        margin-top: 20px;
      }
      &::before {
        content: "※";
        position: absolute;
        left: 0;
        top: 0;
      }
      +.text {
        margin-top: min(20px, #{vw(20,1366)});
        @include sp {
          margin-top: 20px;
        }
      }
    }
    .c-title02.small {
      +.text {
        margin-top: 0;
      }
    }
    .c-title04 {
      +.text {
        margin-top: 22px;
      }
    }
    .img-wrap {
      text-align: center;
    }
  }
  .c-link-block {
    .c-link-box-wrap {
      +.c-link-list-wrap {
        .c-title03 {
          margin-top: min(55px, 4.0263543192vw);
          @include sp {
            margin-top: 18px;
          }
        }
      }
    }
    .c-link-list-wrap {
      .c-title03 {
        margin-top: 0;
      }
    }
  }
}
