@use '../mixin/mixin' as *;
@use '../setting/setting' as *;

/*------------------------------------------------------------------------------
  reset
------------------------------------------------------------------------------*/
html,
input,
textarea,
select,
button {
  font-family: "NotoSans","游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", Meiryo, sans-serif;
  font-weight: 400;
}

html {
  color: #333;
  background: #fff;
  font-size: min(16px, #{vw(16,1366)});
  line-height: 1.6;
  letter-spacing: 0.06em;
  overflow-wrap: break-word;
  @include sp {
    font-size: 14px;
  }
}

body {
  background: #fff;
  margin: 0;
  @include sp {
    position: relative;
    -webkit-appearance: none;
    -webkit-text-size-adjust: 100%;
    &:not(.home) {
      padding-bottom: 54px;
    }
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

img {
  border: 0;
  margin: 0;
  vertical-align: top;
  max-width: 100%;
  height: auto;
}

figure {
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
  &[href^="tel:"] {
    @include pc {
      pointer-events: none;
    }
  }
}

input,
select,
textarea,
button {
  color: inherit;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  appearance: none;
}

input,
select,
textarea {
  @include sp {
    font-size: 16px !important;
  }
}

select::-ms-expand {
  display: none;
}

textarea {
  resize: vertical;
}

@include placeholder(#ccc);

button {
  border: none;
  cursor: pointer;
}

label {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

main {
  display: block;
  @include pc {
    padding-top: min(130px, #{vw(130,1366)});
    @include max(870) {
      padding-top: vw(60,870);
    }
  }
  @include sp {
    padding-top: 60px;
  }
}

#wrapper {
  position: relative;
}


.inner-block {
  margin: 0 auto;
  position: relative;
  @include pc {
    padding: 0 15px;
    max-width: 1260px;
    margin: auto;
    width: 100%;
  }
  @include sp {
    padding: 0 20px;
  }
}

dl,dt,dd {
  padding: 0;
  margin: 0;
}

a,
a::before,
a::after,
button,
button::before,
button::after {
  @include pc {
    transition: $transition01;
  }
}

.ib {
  display: inline-block;
}

.pc {
  @include sp {
    display: none !important;
  }
}

.sp {
  @include pc {
    display: none !important;
  }
}

//独自font-family読み込み
@font-face {
  src: url(../font/NotoSansJP-Regular.otf) format("opentype"),
  url(../font/NotoSansJP-Regular.woff) format("woff");
  font-family: "NotoSans";
  font-weight: 400;
  font-display: swap;
 }
 @font-face {
  src: url(../font/NotoSansJP-Bold.otf) format("opentype"),
  url(../font/NotoSansJP-Bold.woff) format("woff");
  font-family: "NotoSans";
  font-weight: 700;
  font-display: swap;
 }
 @font-face {
  src: url(../font/Lato-Bold.ttf) format("truetype");
  font-family: "Lato-Bold";
  font-weight: 700;
  font-display: swap;
 }


//SVGスプライトリセットCSS
.c-svg {
  display: inline-block;
  fill: currentColor;
  vertical-align: top;
}

// 共通
.c-en{
  font-family: var(--font-family-Lato-B);
}

// 背景
.bg-blue {
  background: #ECF4F8;
}
.bg-skyblue {
  background: $blue;
}
.bg-gray {
  background: $gray03;
}

// アンカー位置
.c-anchor {
  position: relative;
  top: -80px;
}

//文字色
.c-blue {
  color: $blue;
}

.adv-area {
  position: relative;
  overflow: hidden;
}