@use '../mixin/mixin' as *;
@use '../setting/setting' as *;

.c-footer {
  .inner-block {
    max-width: 100%;
  }
  .footer-top {
    background: #fff;
    border-top: 1px solid #ddd;
    position: relative;
    @include sp {
      background: #ECF4F8;
    }
    .inner-block {
      padding: min(30px, #{vw(30,1366)}) min(130px, #{vw(40,1366)});
      @include sp {
        padding: 20px 20px 14px;
      }
    }
    .c-menu {
      display: flex;
      flex-wrap: wrap;
      li {
        @include pc {
          +li {
            margin-left: min(40px, #{vw(40,1366)});
          }
        }
        @include sp {
          margin-right: 30px;
          margin-bottom: 3px;
        }
        a {
          font-size: min(15px, #{vw(15,1366)});
          font-weight: 400;
          @include pc {
            &:hover {
              color: $blue;
            }
          }
          @include sp {
            font-size: min(13px, #{vw(13,375)});
            letter-spacing: 0;
            line-height: 1.6923;
          }
        }
      }
    }
  }
  .footer-bottom {
    background: #97999B;
    .inner-block {
      padding: min(50px, #{vw(50,1366)}) min(130px, #{vw(40,1366)}) min(20px, #{vw(20,1366)});
      @include sp {
        padding: 20px;
      }
    }
    .logo-wrap {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      @include sp {
        .logo {
          max-width: 102px;
        }
        .catchcopy {
          max-width: 141px;
        }
      }
    }
  }
  .copyright {
    color: #fff;
    font-size: min(13px, #{vw(13,1366)});
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
    margin-top: min(10px, #{vw(100,1366)});
    @include sp {
      font-size: 10px;
      margin-top: 30px;
    }
  }
}

// pagetop
#pagetop {
  position: fixed;
  right: 0;
  bottom: 0;
  a {
    background: #ECF7F8;
    display: block;
    height: min(85px, #{vw(85,1366)});
    width: min(85px, #{vw(85,1366)});
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: $green;
    font-size: min(12px, #{vw(12,1366)});
    font-weight: 400;
    padding-top: min(9px, #{vw(9,1366)});
    position: relative;
    text-align: center;
    @include pc {
      @include max(920) {
        height: min(70px, #{vw(85,920)});
        width: min(70px, #{vw(85,920)});
      }
      &:hover {
        background: $blue;
        color: #fff;
        &::before {
          border-color: #fff;
        }
      }
    }
    @include sp {
      height: 60px;
      width: 60px;
      font-size: 10px;
    }
    &::before {
      content: "";
      display: inline-block;
      width: min(8px, #{vw(8,1366)});
      height: min(8px, #{vw(8,1366)});
      border-top: 1px solid $green;
      border-left: 1px solid $green;
      transform: rotate(45deg);
      position: absolute;
      top: max(-15px, #{vw(-15,1366)});
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      @include sp {
        left: 3px;
        height: 6px;
        width: 6px;
      }
    }
  }
}