@use '../mixin/mixin' as *;
@use '../setting/setting' as *;

.c-breadcrumb {
  padding: min(40px, #{vw(40,1366)}) 15px min(55px, #{vw(55,1366)});
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 1260px;
  @include sp {
    flex-wrap: nowrap;
    padding: 24px 20px 35px;
    overflow: scroll;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {  
      display: none;
    }
  }
  li {
    position: relative;
    +li {
      margin-left: min(24px, #{vw(24,1366)});
      @include pc {
        @include max(1000) {
          margin-left: vw(24,1000);
        }
      }
      @include sp {
        margin-left: 14px;
      }
      &::before {
        background: $blue;
        content: "";
        display: block;
        height: min(14px, #{vw(14,1366)});
        width: 1px;
        transform: rotate(15deg);
        position: absolute;
        top: 4px;
        left: max(-11px, #{vw(-11,1366)});
        bottom: 0;
        margin: auto;
        @include pc {
          @include max(1000) {
            top: 2px;
            left: vw(-12,1000);
          }
        }
        @include sp {
          height: 10px;
          left: -7px;
          top: 3px;
        }
      }
    }
    .inn {
      font-size: min(13px, #{vw(13,1366)});
      letter-spacing: 0;
      @include sp {
        font-size: 10px;
      }
    }
    @include pc {
      a {
        &:hover {
          color: $blue;
        }
      }
    }
  }
  &.under {
    padding: min(36px, #{vw(36,1366)}) 18px min(58px, #{vw(58,1366)});
    @include sp {
      padding: 20px 20px 37px;
    }
  }
}