@charset "utf-8";

// placeholder
$placeholderColor: #c3c3c3;

$bp-sp: 640px;
$bp-pc: 641px;

$vw_standard_width: 375;

// transition
$transition01: 0.3s ease-in-out;

// color
$green: #0A616F;
$green02: #48B5AA;
$blue: #13ACC4;
$blue02: #1380C4;
$yellow: #FFDB14;
$orange: #FF6C00;
$gray: #F4F4F4;
$gray02: #676767;
$gray03: #F8F8F8;

:root {
  // font-family
  --font-family-Lato-B: "Lato-Bold", sans-serif;
};