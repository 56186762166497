/* -----------------------------------------------
* home Module
* homeページ用
-------------------------------------------------- */
@use '../../mixin/mixin' as *;
@use '../../setting/setting' as *;

.security {
  .contents-block {
    .inner-block {
      padding-bottom: min(120px, #{vw(120,1366)});
      @include sp {
        padding-bottom: 60px;
      }
    }
  }
  .title-area {
    position: relative;
    padding-bottom: min(71px, #{vw(71,1366)});
    @include sp {
      padding-bottom: 60px;
    }
    &::before {
      right: 0;
    }
    &::after {
      left: min(2px, #{vw(2,1366)});
      @include sp {
        left: 0;
      }
    }
    .tag {
      background: $blue;
      border-radius: 100px;
      color: #fff;
      display: inline-block;
      font-size: min(14px, #{vw(14,1366)});
      padding: min(5px, #{vw(4,1366)}) min(12px, #{vw(12,1366)});
      text-align: center;
      letter-spacing: 0;
      margin: 0 min(10px, #{vw(10,1366)}) min(10px, #{vw(10,1366)}) 0;
      @include sp {
        font-size: 11px;
        padding: 5px 12px;
        margin: 0 10px 10px 0;
      }
    }
    .main-title {
      font-size: min(40px, #{vw(40,1366)});
      font-weight: bold;
      letter-spacing: 0;
      line-height: 1.7;
      margin-top: min(12px, #{vw(12,1366)});
      @include sp{
        font-size: 24px;
        margin-top: 7px;
      }
    }
  }
  .main-area {
    max-width: 1020px;
    margin: auto;
    .wrap {
      +.wrap {
        margin-top: min(58px, #{vw(58,1366)});
        @include sp {
          margin-top: 39px;
        }
      }
      .c-title02.small {
        @include sp {
          margin-bottom: 28px;
        }
      }
      .text {
        line-height: 2;
        @include sp{
          line-height: 1.7;
        }
      }
    }
  }
}
