/* -----------------------------------------------
* home Module
* homeページ用
-------------------------------------------------- */
@use '../../mixin/mixin' as *;
@use '../../setting/setting' as *;

.home {
  .mv-block {
    background: $blue;
    position: relative;
    @include sp {
      padding-bottom: 40px;
    }
    .inner-block {
      padding-top: min(40px, #{vw(40,1366)});
      padding-bottom: min(37px, #{vw(37,1366)});
      @include sp {
        padding-top: 40px;
        padding-bottom: 40px;
      }
    }
    .main-img-l {
      position: absolute;
      bottom: min(25px, #{vw(48,1366)});
      left: max(-192px, #{vw(-192,1366)});
      max-width: min(650px, #{vw(650,1366)});
      @include pc {
        @include min(1520) {
          left: -100px;
        }
        @include max(930) {
          left: max(-150px, #{vw(-150,930)});
        }
      }
    }
    .main-img-r {
      position: absolute;
      bottom: min(25px, #{vw(25,1366)});
      right: max(-232px, #{vw(-232,1366)});
      max-width: min(570px, #{vw(570,1366)});
      @include pc {
        @include min(1520) {
          right: -150px;
        }
        @include max(930) {
          right: max(-170px, #{vw(-170,930)});
        }
      }
    }
    .intro-img {
      position: absolute;
      bottom: min(34px, #{vw(34,1366)});
      right: min(68px, #{vw(68,1366)});
      max-width: min(387px, #{vw(387,1366)});
      @include sp {
        position: relative;
        margin: auto;
        max-width: 335px;
        right: 0;
        bottom: 0;
      }
    }
  }
  .logo-slide-block {
    text-align: center;
    padding: min(40px, #{vw(40,1366)}) 0 min(80px, #{vw(80,1366)});
    @include sp {
      padding: 44px 0 40px;
    }
    .title {
      display: inline-block;
      font-size: min(18px, #{vw(18,1366)});
      font-weight: bold;
      text-align: center;
      letter-spacing: 0;
      padding-bottom: min(10px, #{vw(10,1366)});
      margin-bottom: min(15px, #{vw(15,1366)});
      position: relative;
      @include sp {
        font-size: 18px;
        padding-bottom: 16px;
        margin-bottom: 18px;
      }
      &::before {
        background: url(/img/home/line.svg) no-repeat center center;
        background-size: contain;
        display: block;
        content: "";
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
    .loopSliderWrap {
      position: absolute;
      top: 0;
      left: 0;
      height: 65px;
      overflow: hidden;
      @include sp {
        height: 40px;
      }
    }
    .loop-slider {
      position: relative;
      width: 100%;
      height: 65px;
      overflow: hidden;
      font-size: 0;
      line-height: 65px;
      text-align: left;
      @include sp {
        height: 40px;
        line-height: 40px;
      }
      * {
        margin: 0;
        padding: 0;
      }
      .loop-slider-list {
        display: inline-block;
        height: 65px;
        float: left;
        overflow: hidden;
        white-space: nowrap;
        @include sp {
          height: 40px;
        }
        li {
          display: inline-block;
          height: 100%;
          margin: 0 20px;
          overflow: hidden;
          text-align: center;
          vertical-align: middle;
          img {
            height: auto;
            vertical-align: middle;
            @include sp {
              max-height: 40px;
            }
          }
        }
      }
    }
  }
  .point-link-block {
    .inner-block {
      padding-top: min(60px, #{vw(60,1366)});
      padding-bottom: min(60px, #{vw(60,1366)});
      @include sp {
        padding-top: 40px;
        padding-bottom: 39px;
      }
    }
    .point-title {
      font-weight: bold;
      text-align: center;
      margin-bottom: min(56px, #{vw(56,1366)});
      @include sp {
        margin-bottom: 35px;
      }
      .top {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: min(32px, #{vw(32,1366)});
        @include sp {
          font-size: 20px;
        }
        img {
          margin-right: 5px;
          max-width: 269px;
          @include sp {
            max-width: 171px;
          }
        }
      }
      .bottom {
        display: block;
        font-size: min(40px, #{vw(40,1366)});
        letter-spacing: 0.07em;
        @include sp {
          font-size: 26px;
        }
      }
    }
    .point-link-list {
      display: flex;
      flex-wrap: wrap;
      li {
        width: calc((100% - min(120px, #{vw(120,1366)})) / 5);
        @include pc {
          &:not(:first-child) {
            margin-left: min(30px, #{vw(30,1366)});
          }
        }
        @include sp {
          width: calc((100% - 11px) / 2);
          &:nth-child(2n+2) {
            margin-left: 11px;
          }
          &:nth-child(n+3) {
            margin-top: 40px;
          }
        }
        a {
          display: block;
          position: relative;
          padding-bottom: min(30px, #{vw(30,1366)});
          @include pc {
            &:hover {
              .title {
                .inn {
                  color: $blue;
                  transition: $transition01;
                }
              }
            }
          }
          @include sp {
            padding-bottom: 12px;
          }
          &::before {
            content: "";
            display: inline-block;
            width: 12px;
            height: 12px;
            border-bottom: 2px solid $blue;
            border-right: 2px solid $blue;
            transform: rotate(45deg);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            @include sp {
              width: 8px;
              height: 8px;
            }
          }
        }
        .title {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          @include sp {
            display: block;
            text-align: center;
          }
          .num {
            background: $blue;
            border-radius: 100px;
            color: #fff;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            font-size: min(22px, #{vw(22,1366)});
            font-weight: bold;
            position: relative;
            line-height: 1.5;
            height: min(48px, #{vw(48,1366)});
            width: min(48px, #{vw(48,1366)});
            padding: 0 0 min(2px, #{vw(2,1366)}) min(1px, #{vw(1,1366)});
            z-index: 1;
            @include sp {
              font-size: 18px;
              height: 39px;
              width: 39px;
              margin: 0 auto 13px;
            }
            &::before {
              border-radius: 3px;
              content: "";
              display: inline-block;
              border-style: solid;
              border-width: 12px 10px 0 10px;
              border-color: $blue transparent transparent transparent;
              position: absolute;
              height: 12px;
              width: 20px;
              bottom: 2px;
              right: -3px;
              transform: rotate(-50deg);
              z-index: -1;
              @include pc {
                @include max(1070) {
                  bottom: 1px;
                  right: -5px;
                }
                @include max(780) {
                  bottom: 1px;
                  right: -6px;
                }
              }
              @include sp {
                border-width: 10px 8px 0 8px;
                height: 10px;
                width: 16px;
                transform: rotate(0);
                right: 0;
                left: 0;
                margin: auto;
                bottom: -4px;
              }
            }
          }
          .inn {
            font-size: min(18px, #{vw(18,1366)});
            font-weight: bold;
            letter-spacing: 0;
            margin-left: min(10px, #{vw(10,1366)});
            transition: $transition01;
            width: calc(100% - min(58px, #{vw(58,1366)}));
            @include sp {
              font-size: 16px;
              margin-left: 0;
              width: 100%;
            }
          }
        }
        .img {
          display: block;
          max-width: 120px;
          margin: 20px auto 0;
          @include sp {
            max-width: 100px;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .point-wrap-block {
    padding-bottom: min(100px, #{vw(100,1366)});
    @include sp {
      padding-bottom: 59px;
    }
    .point-wrap {
      &:nth-child(odd) {
        @include pc {
          .l-flex {
            .text-area {
              margin-right: min(60px, #{vw(60,1366)});;
              width: calc((100% - min(585px, #{vw(585,1366)})));
            }
            .img-area {
              width: min(525px, #{vw(525,1366)});
            }
          }
        }
      }
      &:nth-child(even) {
        .inner-block {
          padding-top: min(129px, #{vw(129,1366)});
          @include sp {
            padding-top: 30px;
          }
        }
        .l-flex {
          @include pc {
            flex-direction: row-reverse;
            .img-area {
              margin-top: min(15px, #{vw(15,1366)});;
              width: min(495px, #{vw(495,1366)});
            }
          }
          .text-area {
            background: #fff;
            border-radius: 10px;
            padding: min(77px, #{vw(77,1366)}) min(60px, #{vw(60,1366)}) min(55px, #{vw(55,1366)});
            @include pc {
              margin-left: min(30px, #{vw(30,1366)});
              width: calc((100% - min(525px, #{vw(525,1366)})));
            }
            @include sp {
              padding: 30px 20px;
              .text {
                margin-top: 15px;
              }
            }
            .c-title05 {
              @include pc {
                .num {
                  position: absolute;
                  top: max(-69px, #{vw(-69,1366)});
                  @include max(1000) {
                    top: max(-40px, #{vw(-40,1000)});
                  }
                }
              }
            }
          }
        }
      }
      &.point01 {
        @include pc {
          .text-area {
            margin-right: min(60px, #{vw(60,1366)});;
            width: calc((100% - min(585px, #{vw(585,1366)})));
            max-width: 600px;
          }
          .img-area {
            top: min(15px, #{vw(15,1366)});
            position: relative;
            width: min(525px, #{vw(525,1366)});
          }
        }
      }
      &.point03 {
        .inner-block {
          padding-top: min(100px, #{vw(100,1366)});
          padding-bottom: min(95px, #{vw(95,1366)});
          @include sp {
            padding-top: 40px;
            padding-bottom: 39px;
          }
        }
      }
      &.point02, &.point04 {
        .inner-block {
          padding-bottom: min(60px, #{vw(60,1366)});
          @include sp {
            padding-bottom: 30px;
          }
        }
      }
      &.point04 {
        .l-flex {
          @include pc {
            .img-area {
              margin-top: min(30px, #{vw(30,1366)});
            }
          }
        }
      }
      &.point05 {
        .inner-block {
          padding-top: min(100px, #{vw(100,1366)});
          padding-bottom: min(99px, #{vw(99,1366)});
          @include sp {
            padding-top: 40px;
            padding-bottom: 50px;
          }
        }
        @include pc {
          .l-flex {
            .text-area {
              margin-right: min(60px, #{vw(60,1366)});;
              width: calc((100% - min(660px, #{vw(660,1366)})));
            }
            .img-area {
              margin-top: min(23px, #{vw(23,1366)});
              width: min(600px, #{vw(600,1366)});
            }
          }
        }
        @include sp {
          .text {
            margin-top: 47px;
          }
        }
      }
      .inner-block {
        padding-top: min(80px, #{vw(80,1366)});
        padding-bottom: min(108px, #{vw(108,1366)});
        @include sp {
          padding-top: 60px;
          padding-bottom: 58px;
        }
      }
      .l-flex {
        display: flex;
        flex-wrap: wrap;
        @include sp {
          display: block;
        }
        .text-area {
          position: relative;
          @include sp {
            margin-bottom: 30px;
          }
        }
      }
      .text {
        line-height: 2;
        margin-top: min(35px, #{vw(35,1366)});
        @include sp {
          line-height: 1.7142;
          margin-top: 25px;
        }
      }
      .support-list {
        display: flex;
        flex-wrap: wrap;
        margin-top: min(35px, #{vw(35,1366)});
        @include sp {
          flex-direction: column;
          margin-top: 30px;
        }
        li {
          position: relative;
          width: calc((100% - min(120px, #{vw(120,1366)})) / 3);
          @include sp {
            padding-top: 32px;
            padding-bottom: 29px;
            width: 100%;
            &:first-child {
              padding-top: 0;
            }
            &:nth-child(2) {
              .img {
                max-width: 156px;
                position: relative;
                right: 2px;
              }
            }
            &:last-child {
              padding-bottom: 0;
              .img {
                max-width: 155px;
                position: relative;
                left: 2px;
              }
            }
          }
          +li {
            @include pc {
              margin-left: min(60px, #{vw(60,1366)});
            }
            &::before {
              background: rgba($color: $blue, $alpha: 0.2);
              content: "";
              height: 100%;
              width: 1px;
              position: absolute;
              top: 0;
              left: max(-30px, #{vw(-30,1366)});
              @include sp {
                left: 0;
                height: 1px;
                width: 100%;
              }
            }
          }
          .img {
            display: block;
            margin: auto;
            max-width: min(160px, #{vw(160,1366)});
            @include sp {
              max-width: 152px;
            }
          }
          .title {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            font-size: min(20px, #{vw(20,1366)});
            font-weight: bold;
            line-height: 1.7;
            text-align: center;
            margin-top: min(16px, #{vw(16,1366)});
            @include sp {
              font-size: 16px;
              line-height: 1.6875;
              margin-top: 21px;
            }
          }
          .text {
            font-size: min(14px, #{vw(14,1366)});
            line-height: 1.7;
            margin-top: min(20px, #{vw(20,1366)});
            @include sp {
              font-size: 14px;
              margin-top: 17px;
            }
          }
        }
      }
      .btn-wrap {
        margin-top: min(35px, #{vw(35,1366)});
        @include sp {
          margin-top: 20px;
        }
        .c-btn02 {
          &.left {
            @include sp {
              padding: 21px 25px 21px 15px;
              margin: auto;
            }
          }
        }
      }
    }
    .banner-box {
      border: 1px solid #ddd;
      border-radius: 10px;
      display: flex;
      flex-wrap: wrap;
      max-width: 810px;
      margin: auto;
      @include sp {
        max-width: calc(100% - 40px);
      }
      .img {
        width: min(315px, #{vw(315,1366)});
        @include sp {
          width: 100%;
          img {
            border-radius: 10px 10px 0 0;
          }
        }
      }
      .text-area {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(100% - min(315px, #{vw(315,1366)}));
        @include sp {
          padding: 27px 10px 40px;
          line-height: 1.5;
          width: 100%;
          .text {
            font-size: 18px;
            text-align: center;
          }
        }
        .c-btn02 {
          min-width: min(285px, #{vw(285,1366)});
          margin-top: min(27px, #{vw(27,1366)});
          @include sp {
            min-width: 200px;
            margin-top: 25px;
            padding: 20px 30px;
          }
        }
      }
    }
  }
  .functions-block {
    .inner-block {
      padding-top: min(70px, #{vw(70,1366)});
      padding-bottom: min(100px, #{vw(100,1366)});
      @include sp {
        padding-top: 37px;
        padding-bottom: 58px;
      }
    }
    .c-link-list {
      margin-top: min(55px, #{vw(55,1366)});
      margin-bottom: min(60px, #{vw(60,1366)});
      @include sp {
        margin-top: 35px;
        margin-bottom: 37px;
      }
    }
  }
  .intro-block {
    .inner-block {
      padding-top: min(87px, #{vw(87,1366)});
      padding-bottom: min(97px, #{vw(97,1366)});
      @include sp {
        padding-top: 57px;
        padding-bottom: 60px;
      }
    }
    .intro-list {
      display: flex;
      flex-wrap: wrap;
      margin: min(55px, #{vw(55,1366)}) 0 min(60px, #{vw(60,1366)});
      @include sp {
        flex-direction: column;
        margin: 36px 0;
      }
      li {
        width: calc((100% - min(60px, #{vw(60,1366)})) / 3);
        @include pc {
          &:not(:nth-child(3n+1)) {
            margin-left: min(30px, #{vw(30,1366)});
          }
        }
        @include sp {
          width: 100%;
          +li {
            margin-top: 28px;
          }
        }
      }
      a {
        &:hover {
          .title {
            color: $blue;
            transition: $transition01;
          }
        }
      }
      .img {
        border-radius: 10px;
        img {
          border-radius: 10px;
        }
      }
      .title {
        font-size: min(20px, #{vw(20,1366)});
        font-weight: bold;
        letter-spacing: 0;
        margin: min(30px, #{vw(30,1366)}) 0 min(30px, #{vw(30,1366)});
        transition: $transition01;
        @include sp {
          font-size: 18px;
          margin: 20px 0 15px;
          line-height: 1.7222;
        }
      }
      .text {
        color: #797979;
        font-size: min(15px, #{vw(15,1366)});
        padding-left: min(20px, #{vw(20,1366)});
        position: relative;
        @include sp {
          font-size: 14px;
          padding-left: 20px;
        }
        &::before {
          background: #797979;
          content: "";
          display: block;
          height: 1px;
          width: 10px;
          position: absolute;
          top: min(12px, #{vw(12,1366)});
          left: 0;
          @include sp {
            top: 10px;
          }
        }
      }
    }
  }
}
