@use '../mixin/mixin' as *;
@use '../setting/setting' as *;


.anchor-block {
  .inner-block {
    padding-bottom: min(80px, #{vw(80,1366)});
    @include sp {
      padding-bottom: 40px;
    }
  }
}
.range-wrap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: min(20px, #{vw(20,1366)});
  @include max(730) {
    display: none;
  }
  .range {
    border-left: 5px dotted $gray;
    color: $gray02;
    font-size: min(14px, #{vw(14,1366)});
    font-weight: bold;
    text-align: center;
    position: relative;
    padding: min(3px, #{vw(3,1366)}) min(10px, #{vw(10,1366)}) min(4px, #{vw(4,1366)});
    .inn {
      display: block;
      position: relative;
      &::before, &::after {
        background: #ddd;
        content: "";
        display: block;
        height: 1px;
        width: min(133px, #{vw(133,1366)});
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      &::before {
        left: min(10px, #{vw(10,1366)});
      }
      &::after {
        right: min(10px, #{vw(10,1366)});
      }
    }
    &::before, &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      border-style: solid;
      height: min(9px, #{vw(9,1366)});
      width: min(12px, #{vw(12,1366)});
    }
    &::before {
      border-width: min(6px, #{vw(6,1366)}) min(9px, #{vw(9,1366)}) min(6px, #{vw(6,1366)}) 0;
      border-color: transparent #ddd transparent transparent;
      left: min(10px, #{vw(10,1366)});
    }
    &::after {
      border-width: min(6px, #{vw(6,1366)}) 0 min(6px, #{vw(6,1366)}) min(9px, #{vw(9,1366)});
      border-color: transparent transparent transparent #ddd;
      right: min(10px, #{vw(10,1366)});
    }
    &:first-child {
      width: 34%;
    }
    &:last-child {
      border-right: 5px dotted $gray;
      width: 66%;
      .inn {
        &::before, &::after {
        width: min(340px, #{vw(340,1366)});
      }
      }
    }
  }
}
.c-anchor-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 1230px;
  margin: auto;
  @include pc {
    box-shadow: 0px 3px 14px rgba(181, 181, 181, 0.16);
  }
  li {
    width: calc(100% / 6);
    @include pc {
      &:first-child {
        .white-box {
          border-left: 1px solid #ddd;
        }
      }
      &:last-child {
        .white-box {
          border-right: 1px solid #ddd;
        }
      }
      &:nth-child(4) {
        .arrow-title  {
          .inn {
            &::after {
              border-color: transparent transparent transparent $blue;
            }
          }
        }
      }
      &:nth-child(5) {
        .arrow-title  {
          .inn {
            &::after {
              border-color: transparent transparent transparent $blue;
            }
          }
        }
      }
      @include min(731) {
        +li {
          .white-box {
            &::before {
              background: #ddd;
              content: "";
              display: block;
              height: calc(100% - min(20px, #{vw(20,1366)}));
              width: 1px;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              margin: auto;
            }
          }
          .arrow-title {
            .inn {
              display: block;
              position: relative;
              &::before, &::after {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                margin: auto;
                top: 0;
                bottom: 0;
                border-style: solid;
                border-width: min(30px, #{vw(30,1366)}) 0 min(30px, #{vw(30,1366)}) min(14px, #{vw(14,1366)});
                z-index: 10;
                @include max(1050) {
                  border-width: min(35px, #{vw(35,1050)}) 0 min(35px, #{vw(35,1050)}) min(19px, #{vw(19,1050)});
                }
              }
              &:before{
                left: max(-8px, #{vw(-8,1366)});
                border-color: transparent transparent transparent #fff;
              }
              &:after{
                left: max(-10px, #{vw(-10,1366)});
                border-color: transparent transparent transparent $blue02;
              }
            }
          }
        }
      }
    }
    @include max(730) {
      box-shadow: 0px 3px 14px rgba(181, 181, 181, 0.4);
      border-radius: 5px;
      width: calc((100% - 19px) / 2);
      &:nth-child(2n+2) {
        margin-left: 19px;
      }
      &:nth-child(n+3) {
        margin-top: 20px;
      }
    }
  }
  a {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    &::before {
      content: "";
      width: 6px;
      height: 6px;
      border-bottom: 2px solid $blue;
      border-right: 2px solid $blue;
      transform: rotate(45deg);
      position: absolute;
      bottom: min(18px, #{vw(18,1366)});
      left: 0;
      right: 0;
      margin: auto;
      @include sp {
        width: 5px;
        height: 5px;
        bottom: 10px;
      }
    }
    @include pc {
      &:hover {
        .title {
          color: $blue;
          transition: $transition01;
        }
      }
    }
  }
  .arrow-title {
    background: $blue;
    color: #fff;
    display: block;
    font-size: min(14px, #{vw(14,1366)});
    font-weight: bold;
    text-align: center;
    padding: min(13px, #{vw(13,1366)}) min(10px, #{vw(10,1366)}) min(12px, #{vw(12,1366)});
    overflow: hidden;
    @include sp {
      border-radius: 5px 5px 0 0;
      font-size: 13px;
      padding: 3px 10px 2px;
    }
    &.blue {
      background: $blue02;
    }
    &.skyblue {
      background: $blue;
    }
    &.green {
      background: $green02;
      .inn {
        &::after {
          border-color: transparent transparent transparent $green02;
        }
      }
    }
  }
  .white-box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: min(20px, #{vw(20,1366)}) min(10px, #{vw(10,1366)}) min(42px, #{vw(42,1366)});
    position: relative;
    flex-grow: 1;
    @include pc {
      border-bottom: 1px solid #ddd;
    }
    @include sp {
      border-radius: 0 0 5px 5px;
      padding: 10px 10px 26px;
    }
    .title {
      display: block;
      font-size: min(16px, #{vw(16,1366)});
      font-weight: bold;
      line-height: 1.4375;
      margin-top: min(18px, #{vw(18,1366)});
      text-align: center;
      transition: $transition01;
      @include sp {
        font-size: min(13px, #{vw(13)});
        line-height: 1.3;
        margin-top: 15px;
        letter-spacing: 0;
      }
    }
    .icon {
      @include sp {
        display: flex;
        align-items: center;
        max-width: 60px;
        min-height: 60px;
      }
    }
  }
} 