@use '../mixin/mixin' as *;
@use '../setting/setting' as *;

.c-title01 {
  .c-en {
    color: $blue;
    display: block;
    font-size: min(18px, #{vw(18,1366)});
    font-weight: bold;
    line-height: 1.333;
    @include sp {
      font-size: 14px;
      line-height: 2;
    }
  }
  .main {
    display: block;
    font-size: min(40px, #{vw(40,1366)});
    font-weight: bold;
    margin-top: min(5px, #{vw(5,1366)});
    line-height: 1.7;
    letter-spacing: 0;
    @include sp {
      font-size: 24px;
      letter-spacing: 0;
    }
    &.big {
      @include sp {
        font-size: 26px;
      }
    }
  }
  &.small {
    .c-en {
      font-size: min(16px, #{vw(16,1366)});
      @include sp {
        font-size: 13px;
        letter-spacing: 0;
        line-height: 1;
      }
    }
    .main {
      font-size: min(32px, #{vw(32,1366)});
      margin-top: 0;
      letter-spacing: 0.06em;
      @include sp {
        font-size: 26px;
      }
    }
  }
  &.center {
    text-align: center;
  }
}

.c-title02 {
  font-weight: bold;
  font-size: min(32px, #{vw(32,1366)});
  position: relative;
  letter-spacing: 0.07em;
  padding-top: min(42px, #{vw(42,1366)});
  margin-bottom: min(32px, #{vw(32,1366)});
  line-height: 1.6875;
  @include sp {
    font-size: 22px;
    padding-top: 32px;
    margin-bottom: 18px;
    line-height: 1.6818;
    letter-spacing: 0;
  }
  &::before {
    background: url(/img/common/icon_line.svg) no-repeat center center;
    background-size: contain;
    content: "";
    display: block;
    height: min(5px, #{vw(5,1366)});
    width: min(48px, #{vw(48,1366)});
    position: absolute;
    top: 0;
    left: 0;
    @include sp {
      width: 49px;
      height: 5px;
    }
  }
  &.small {
    font-size: min(24px, #{vw(24,1366)});
    letter-spacing: 0;
    padding-top: min(20px, #{vw(20,1366)});
    margin-bottom: min(12px, #{vw(12,1366)});
    @include sp {
      font-size: 18px;
      padding-top: 20px;
      margin-bottom: 27px;
    }
    &::before {
      height: min(3px, #{vw(3,1366)});
      width: min(34px, #{vw(34,1366)});
      @include sp {
        height: 3px;
        width: 34px;
      }
    }
  }
}

.c-title03 {
  font-size: min(22px, #{vw(22,1366)});
  font-weight: bold;
  padding-left: min(15px, #{vw(15,1366)});
  position: relative;
  line-height: 1.6818;
  margin-top: min(55px, #{vw(55,1366)});
  margin-bottom: min(20px, #{vw(20,1366)});
  @include sp {
    font-size: 16px;
    padding-left: 15px;
    margin-top: 18px;
    margin-bottom: 10px;
  }
  &:not(.sub) {
    &::before {
      background: $blue;
      border-radius: 2px;
      height: min(10px, #{vw(10,1366)});
      width: min(10px, #{vw(10,1366)});
      top: min(14px, #{vw(14,1366)});
      @include sp {
        height: 10px;
        width: 10px;
        top: 8px;
      }
    }
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
  }
  &.sub {
    margin-top: min(35px, #{vw(35,1366)});
    padding-left: min(20px, #{vw(20,1366)});
    @include sp {
      padding-left: 15px;
      margin-top: 25px;
    }
    &::before {
      content: "※";
    }
  }
}

.c-title04 {
  font-size: min(24px, #{vw(24,1366)});
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.7;
  margin-bottom: min(32px, #{vw(32,1366)});
  @include sp {
    font-size: 18px;
    margin-bottom: 22px;
  }
}

.c-title05 {
  @include sp {
    display: flex;
    flex-wrap: wrap;
    &.center {
      align-items: center;
    }
  }
  .num {
    color: $blue;
    display: inline-block;
    font-size: min(80px, #{vw(80,1366)});
    position: relative;
    text-align: center;
    padding-top: min(14px, #{vw(14,1366)});
    line-height: 1;
    margin-bottom: min(25px, #{vw(25,1366)});
    letter-spacing: 0.08em;
    @include sp {
      font-size: min(52px, #{vw(52,375)});
      width: min(64px, #{vw(64,375)});
      margin-bottom: 0;
      padding-top: min(10px, #{vw(10,375)});
      white-space: nowrap
    }
    &::before {
      background: $blue;
      border-radius: 100px;
      content: "";
      display: block;
      height: 4px;
      width: 100%;
      position: absolute;
      left: min(3px, #{vw(3,1366)});
      top: max(-1px, #{vw(-1,1366)});
      max-width: min(92px, #{vw(92,1366)});
      @include sp {
        height: 3px;
        max-width: 58px;
      }
    }
    .en {
      display: block;
      font-size: min(26px, #{vw(26,1366)});
      letter-spacing: 0.1em;
      line-height: 1;
      position: relative;
      left: max(-2px, #{vw(-2,1366)});
      @include sp {
        font-size: min(16px, #{vw(16,375)});
        left: max(-1px, #{vw(-1,375)});
      }
    }
  }
  .main {
    display: block;
    font-size: min(28px, #{vw(28,1366)});
    font-weight: bold;
    line-height: 1.7;
    letter-spacing: 0.06em;
    @include sp {
      font-size: 20px;
      margin-left: min(20px, #{vw(20,375)});
      width: calc(100% - min(84px, #{vw(84,375)}));
      letter-spacing: 0;
    }
  }
  &.l-flex {
    @include pc {
      align-items: center;
      .num {
        width: min(106px, #{vw(106,1366)});
      }
      .main {
        margin-top: min(15px, #{vw(15,1366)});;
        margin-left: min(23px, #{vw(23,1366)});
        letter-spacing: 0.057em;
        width: calc(100% - min(129px, #{vw(129,1366)}));
      }
      +.text {
        margin-top: min(20px, #{vw(20,1366)})!important;
      }
    }
    @include sp {
      display: flex!important;
      flex-wrap: wrap;
    }
  }
}

.bg-skyblue {
  .c-title01 {
    .c-en{
      color: #fff;
    }
    .main {
      color: #fff;
    }
  }
}