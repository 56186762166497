/* -----------------------------------------------
* home Module
* homeページ用
-------------------------------------------------- */
@use '../../mixin/mixin' as *;

.purpose {
  .purpose-block {
    .inner-block {
      padding-top: min(84px, #{vw(84,1366)});
      padding-bottom: min(120px, #{vw(120,1366)});
      @include sp {
        padding-top: 40px;
        padding-bottom: 60px;
      }
    }
  }
}
